import React from 'react';

import Card from 'Components/Card';
import LandingPagesData from 'Data/LandingPagesData';
import LandingPagesTable from '../../tables/LandingPagesTable';

const AdvertsScene = () => {
  return (
    <LandingPagesData>
      {({ isLoading, isError, data, removeLandingPage, getLandingPages }) => {
        return (
          <React.Fragment>
            <Card>
              <LandingPagesTable
                isLoading={isLoading}
                isError={isError}
                data={data}
                removeLandingPage={removeLandingPage}
                getLandingPages={getLandingPages}
              />
            </Card>
          </React.Fragment>
        );
      }}
    </LandingPagesData>
  );
};

export default AdvertsScene;
