import React, { useEffect, useState } from 'react';

import { Link } from 'react-router-dom';
import { Table } from 'antd';

import Card from 'Components/Card';
import Media from 'react-media';
import Title from 'Components/Title';
import api from 'api';

const DashboardMostAdverts = props => {
  const [isLoading, setloading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    let didCancel = false;
    setloading(true);
    const fetchData = async () => {
      const url = '/top10Users';
      try {
        const { data } = await api.get(url);
        if (!didCancel) {
          setUsers(data);
          setloading(false);
        }
      } catch (error) {
        setIsError(true);
        if (!didCancel) {
          setUsers([]);
        }
      }
    };
    fetchData();
    return () => {
      didCancel = true;
    };
  }, []);

  const columns = [
    {
      title: 'Name',
      dataIndex: 'profile',
      key: 'profile',
      width: 200,
      render: (profile, r) => {
        const { firstName, lastName } = profile || '';
        return (
          <Link to={`user/${r._id}`}>
            <b>
              {firstName} {lastName}
            </b>
          </Link>
        );
      }
    },
    {
      title: 'E-Mail',
      dataIndex: 'email',
      key: 'email',
      align: 'left',
      hideOnSmall: true
    },
    {
      title: 'Adverts',
      dataIndex: 'adverts_count',
      key: 'adverts_count',
      width: 200,
      align: 'center',
      hideOnSmall: true,
      render: (v, i) => {
        return `${v} Adverts`;
      }
    }
  ];
  const getResponsiveColumns = smallScreen =>
    columns.filter(({ hideOnSmall = false }) => !(smallScreen && hideOnSmall));

  if (isError) {
    return (
      <div>
        <Title title='There has been an error.' />
      </div>
    );
  }

  return (
    <Card>
      <Title title='Top 10 Users' />

      <Media query='(max-width: 780px)'>
        {smallScreen => {
          return (
            <Table
              showHeader={false}
              columns={getResponsiveColumns(smallScreen)}
              dataSource={users}
              loading={isLoading}
              rowKey={users._id}
              pagination={false}
            />
          );
        }}
      </Media>
    </Card>
  );
};

export default DashboardMostAdverts;
