var AWS = require('aws-sdk');

AWS.config.update({
  region: 'eu-west-2',
  credentials: {
    accessKeyId: process.env.REACT_APP_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_SECRET_KEY,
  },
});

var docClient = new AWS.DynamoDB.DocumentClient();

module.exports = (TableName) =>
  new Promise((resolve) => {
    const params = {
      TableName,
    };

    let list = [];

    function onScan(err, data) {
      if (err) {
        console.error(
          'Unable to scan the table. Error JSON:',
          JSON.stringify(err, null, 2)
        );
      } else {
        list = list.concat(data.Items);

        if (typeof data.LastEvaluatedKey !== 'undefined') {
          params.ExclusiveStartKey = data.LastEvaluatedKey;
          docClient.scan(params, onScan);
        } else {
          resolve(list);
        }
      }
    }

    docClient.scan(params, onScan);
  });
