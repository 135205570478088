import { Alert, Col, Result, Row, Spin, Table } from 'antd';
import { Link } from 'react-router-dom';
import AdvertsData from 'Data/AdvertsData';
import Card from 'Components/Card';
import React, { useState } from 'react';
import Title from 'Components/Title';
import TransactionsData from 'Data/TransactionsData';
import UserData from 'Data/UsersData';
import UserForm from './UserForm';
import moment from 'moment';

const UserScene = (props) => {
  const [name, setName] = useState('');
  const { params } = props.match || {};

  const columns = [
    {
      title: 'Headline',
      dataIndex: 'headline',
      key: 'headline',
      render: (i, r) => <Link to={`/adverts/${r._id}`}>{i}</Link>,
    },
    {
      title: 'Created',
      dataIndex: 'createdAt',
      key: 'createdAt',

      render: (i, r) => {
        return moment(i).calendar(null, {
          sameDay: '[Today]',
          nextDay: '[Tomorrow]',
          nextWeek: 'dddd',
          lastDay: '[Yesterday]',
          lastWeek: 'dddd',
          sameElse: 'DD/MM/YYYY',
        });
      },
    },
  ];
  const transactionColumns = [
    {
      title: 'ID',
      dataIndex: 'transactionId',
      key: 'id',
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price',
      render: (r) => `£${r}`,
    },
    {
      title: 'Advert',
      dataIndex: 'advertId',
      key: 'advertId',
      render: (i) => <Link to={`/adverts/${i._id}`}>{i.headline}</Link>,
    },
    {
      title: 'Created',
      dataIndex: 'createdAt',
      key: 'createdAt',

      render: (i, r) => {
        return moment(i).calendar(null, {
          sameDay: '[Today]',
          nextDay: '[Tomorrow]',
          nextWeek: 'dddd',
          lastDay: '[Yesterday]',
          lastWeek: 'dddd',
          sameElse: 'DD/MM/YYYY',
        });
      },
    },
  ];

  return (
    <Row span={24}>
      <Col xs={{ span: 24 }} lg={{ span: 12 }}>
        <UserData userId={params.id}>
          {({
            isLoading,
            isError,
            data: user,
            onUpdate,
            onSuspendUser,
            onRestoreUser,
            onRemoveUser,
            onResetPassword,
          }) => {
            if (isError) {
              return (
                <Result
                  status='500'
                  title='Something went wrong getting the data '
                />
              );
            }

            if (isLoading) {
              return <Spin />;
            }

            if (user && user.profile) {
              const name =
                user.profile.businessName ||
                `${user.profile.firstName} ${user.profile.lastName}`;

              setName(name);
            }

            if (user.deletedAt) {
              return (
                <Alert
                  style={{ marginBottom: '2rem' }}
                  message='Deleted User'
                  description='This user has been removed from whickr'
                  type='error'
                  showIcon
                />
              );
            }

            return (
              <Card>
                {user.suspendedAt && (
                  <Alert
                    style={{ marginBottom: '2rem' }}
                    message='Suspended User'
                    description='This user has been Suspended from whickr'
                    type='warning'
                    showIcon
                  />
                )}
                <UserForm
                  onUpdate={onUpdate}
                  onSuspendUser={onSuspendUser}
                  onRestoreUser={onRestoreUser}
                  onRemoveUser={onRemoveUser}
                  onResetPassword={onResetPassword}
                  isLoading={isLoading}
                  isError={isError}
                  data={user}
                  {...props}
                />
              </Card>
            );
          }}
        </UserData>
      </Col>
      <Col xs={{ span: 24 }} lg={{ span: 12 }}>
        <Card>
          <Title title={`${name} Adverts`} />
          <AdvertsData query={{ userId: params.id }}>
            {({ isLoading, isError, data }) => {
              if (isError) {
                return (
                  <Result
                    status='500'
                    title='Something went wrong getting this users adverts '
                  />
                );
              }

              return (
                <Table
                  rowKey='_id'
                  locale={{ emptyText: 'No Adverts Found' }}
                  showHeader={false}
                  dataSource={data}
                  columns={columns}
                  loading={isLoading}
                />
              );
            }}
          </AdvertsData>
        </Card>
        <Card>
          <Title title={`${name} Transactions`} />
          <TransactionsData query={{ userId: params.id }}>
            {({ isLoading, isError, data }) => {
              if (isError) {
                return (
                  <Result
                    status='500'
                    title='Something went wrong getting this users Transactions '
                  />
                );
              }

              return (
                <Table
                  rowKey='_id'
                  locale={{ emptyText: 'No Transactions Found' }}
                  showHeader={false}
                  dataSource={data}
                  columns={transactionColumns}
                  loading={isLoading}
                />
              );
            }}
          </TransactionsData>
        </Card>
      </Col>
    </Row>
  );
};

export default UserScene;
