import React from 'react';

import { Col, Row } from 'antd';

import DashboardLatestAdverts from 'tables/DashboardLatestAdverts';
import DashboardMostAdverts from 'tables/DashboardMostAdverts';
import DashboardRecentlySold from 'tables/DashboardRecentlySold';
import DashboardUsers from 'tables/DashboardUsers';
import MetricsManager from 'Components/MetricsManager';

const DashboardScene = props => {
  return (
    <div>
      <div
        style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}
      >
        <MetricsManager />
      </div>

      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={24} xl={12}>
          <DashboardUsers />
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={12}>
          <DashboardMostAdverts />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={24} xl={12}>
          <DashboardLatestAdverts />
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={12}>
          <DashboardRecentlySold />
        </Col>
      </Row>
    </div>
  );
};

export default DashboardScene;
