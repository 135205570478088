import React from 'react';

import { Area, AreaChart, ResponsiveContainer } from 'recharts';
import { Spin, Statistic } from 'antd';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 200px;
  background: white;
  border-radius: 5px;
  text-align: ${props => (props.align ? props.align : 'left')};
  margin-top: 2rem;
  margin-bottom: 2rem;
  height: 100px;
  box-shadow: ${props =>
    !props.noShadow && '0 37.125px 70px -12.125px rgba(0, 0, 0, 0.2)'};
  transition: 0.5s;
  :hover {
    box-shadow: ${props =>
      !props.noShadow && '0 37.125px 70px -12.125px rgba(0, 0, 0, 0.3)'};
  }
`;

const MetricCard = ({
  isLoading,
  isError,
  label = '',
  value = 0,
  chartData = [],
  chartColour = '#008C44',
  prefix,
}) => {
  return (
    <Container align='center' noShadow>
      {isLoading ? (
        <Spin size='small' />
      ) : (
        <React.Fragment>
          <div
            style={{
              position: 'absolute',
              zIndex: 1,
              height: '100px',
              width: '100%',
            }}
          >
            {chartData && chartData.length > 1 && (
              <ResponsiveContainer>
                <AreaChart
                  data={chartData}
                  margin={{ top: 0, right: 0, left: -10, bottom: 0 }}
                >
                  <Area
                    type='monotone'
                    dataKey='count'
                    stroke={chartColour}
                    fill={chartColour}
                  />
                </AreaChart>
              </ResponsiveContainer>
            )}
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              background: 'rgba(255,255,255,0.7)',
              width: '100%',
              height: '100%',
              zIndex: 2,
            }}
          >
            <Statistic
              prefix={prefix}
              title={label}
              value={value}
              precision={0}
            />
          </div>
        </React.Fragment>
      )}
    </Container>
  );
};

export default MetricCard;
