import React, { useEffect, useState } from 'react';

import { message } from 'antd';
import Isemail from 'isemail';
import api from 'api';

const UsersData = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  const url = props.userId ? `/User/${props.userId}` : '/User';

  const getUsers = async () => {
    setIsLoading(true);
    const userUrl = props.userId ? `/User/${props.userId}` : '/User';

    try {
      const { data, headers } = await api.get(userUrl, {
        force: true,
        params: {
          query: { ...props.query, deletedAt: { $exists: 0 } },
          sort: props.sort || { 'profile.firstName': 1 },
          populate: 'adverts_count',
          limit: props.limit,
          skip: props.skip || 0,
        },
      });

      if (headers && headers['x-total-count']) {
        setCount(headers['x-total-count']);
      }

      setData(data);
    } catch (error) {
      setIsError(true);
      console.error(error);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsError(false);
      setIsLoading(true);

      try {
        const { data, headers } = await api.get(url, {
          params: {
            query: props.query,
            sort: props.sort || { createdAt: -1 },
            populate: 'adverts_count',
            limit: props.limit,
            skip: props.skip || 0,
          },
        });
        if (headers && headers['x-total-count']) {
          setCount(headers['x-total-count']);
        }
        setData(data);
      } catch (error) {
        setIsError(true);
        console.error(error);
      }

      setIsLoading(false);
    };

    fetchData();
  }, [props.limit, props.query, props.skip, props.sort, url]);

  const search = async (query) => {
    setIsError(false);
    setIsLoading(true);

    let defaultOptions = {
      params: {
        query: { deletedAt: { $exists: 0 } },
        sort: { createdAt: -1 },
        limit: 10,
        populate: 'adverts_count',
      },
    };
    const emailQuery = { 'emails.address': query };

    const searchQuery = { $text: { $search: query } };

    const finalQuery =
      query && Isemail.validate(query) ? emailQuery : searchQuery;

    const searchOptions = {
      params: {
        query: finalQuery,
        select: {
          'profile.firstName': 1,
          'profile.lastName': 1,
          createdAt: 1,
          emails: 1,
          score: { $meta: 'textScore' },
        },
        populate: 'adverts_count',
        sort: { score: { $meta: 'textScore' } },
        limit: 30,
      },
    };

    const options = !!query ? searchOptions : defaultOptions;

    try {
      const { data, headers } = await api.get(url, options);

      if (headers && headers['x-total-count']) {
        setCount(headers['x-total-count']);
      } else {
        setCount(data.length);
      }
      setData(data);
    } catch (error) {
      setIsError(true);
      console.error(error);
    }

    setIsLoading(false);
  };

  const onSuspendUser = async (userId) => {
    try {
      await api.post(`/User/${userId}/suspend`);
      message.info('User has been suspended');
      getUsers();
    } catch (e) {
      console.error(e);
      message.error(e.message);
    }
  };

  const onRemoveUser = async (userId) => {
    try {
      await api.delete(`/User/${userId}/delete`);
      message.info('User has been deleted');
      getUsers();
    } catch (e) {
      console.error(e);
      message.error(e.message);
    }
  };

  const onRestoreUser = async (userId) => {
    try {
      await api.post(`/User/${userId}/restore`);
      message.success('User has been restored');
      getUsers();
    } catch (e) {
      console.error(e);
      message.error(e.message);
    }
  };

  const onResetPassword = async (email) => {
    try {
      await api.post(`/User/${email}/resetPassword`);

      message.success('Reset Password');
    } catch (e) {
      console.error(e);

      message.error(e.message);
    }
  };

  const onUpdate = async (id, values) => {
    try {
      await api.patch(`/User/${id}`, values);

      message.success('Updated User');
    } catch (e) {
      message.error(e.message);
    }
  };

  return props.children({
    isLoading,
    isError,
    data,
    search,
    count,
    onUpdate,
    onSuspendUser,
    onRestoreUser,
    onRemoveUser,
    onResetPassword,
  });
};

const WrappedUsersData = React.memo(UsersData);

export default WrappedUsersData;
