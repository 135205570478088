import api from 'api.js';
import auth0 from 'auth0-js';

class Auth {
  constructor() {
    this.auth0 = new auth0.WebAuth({
      domain: process.env.REACT_APP_AUTH0_DOMAIN,
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
      responseType: 'id_token',
      scope: 'openid profile',
      redirectUri: process.env.REACT_APP_AUTH0_REDIRECT_URI,
      prompt: 'none'
    });
    this.authFlag = 'isLoggedIn';

    this.getProfile = this.getProfile.bind(this);
    this.handleAuthentication = this.handleAuthentication.bind(this);
    this.isAuthenticated = this.isAuthenticated.bind(this);
    this.signIn = this.signIn.bind(this);
    this.signOut = this.signOut.bind(this);
  }

  getProfile() {
    return this.profile;
  }

  getIdToken() {
    return this.idToken;
  }

  isAuthenticated() {
    return JSON.parse(localStorage.getItem(this.authFlag));
  }

  signIn() {
    this.auth0.authorize();
  }

  handleAuthentication() {
    return new Promise((resolve, reject) => {
      this.auth0.parseHash((err, authResult) => {
        if (err) return reject(err);
        if (!authResult || !authResult.idToken) {
          return reject(err);
        }
        this.setSession(authResult);
        resolve();
      });
    });
  }

  setSession(authResult) {
    this.idToken = authResult.idToken;
    this.profile = authResult.idTokenPayload;
    // set the time that the id token will expire at
    this.expiresAt = authResult.idTokenPayload.exp * 1000;

    api.defaults.headers.common['Authorization'] = `Bearer ${this.idToken}`;
    localStorage.setItem(this.authFlag, JSON.stringify(true));
  }

  signOut() {
    // clear id token, profile, and expiration
    api.defaults.headers.common['Authorization'] = null;

    this.idToken = null;
    this.profile = null;
    this.expiresAt = null;
    localStorage.setItem(this.authFlag, JSON.stringify(false));

    this.auth0.logout({
      returnTo: window.location.origin + '/logout'
    });
  }

  silentAuth() {
    return new Promise((resolve, reject) => {
      this.auth0.checkSession({}, (err, authResult) => {
        if (err) {
          localStorage.removeItem(this.authFlag);

          return reject(err);
        }

        this.setSession(authResult);
        resolve();
      });
    });
  }
}

const auth0Client = new Auth();

export default auth0Client;
