import React, { useState } from "react";

import { Spin, Tabs } from "antd";
import AddDisciplineForm from "./AddDisciplineForm";
import DisciplineData from "Data/DisciplineData";
import EditableTable from "tables/EditableTableComponent";
import HorseTypeData from "Data/HorseTypeData";
import Title from "Components/Title";
const { TabPane } = Tabs;

const ColourManager = () => {
  const [shouldShowForm, setShowForm] = useState(false);

  return (
    <DisciplineData>
      {({ isLoading, isError, data = [], remove, restore, add, edit }) => {
        const columns = [
          {
            title: "Label",
            dataIndex: "label",
            editable: true,
            key: "label",
            render: item => {
              return <b>{item}</b>;
            }
          }
        ];

        return (
          <div>
            <Title
              title="Disciplines"
              icon={shouldShowForm ? "close" : "plus"}
              onClick={() => setShowForm(!shouldShowForm)}
            />
            {shouldShowForm && <AddDisciplineForm addDiciplineFunction={add} />}
            <HorseTypeData>
              {({ isLoading: loading, isError: error, data: types }) => {
                if (error) {
                  return <div />;
                }
                if (loading) {
                  return <Spin />;
                }

                if (types.length < 1) {
                  return (
                    <Tabs defaultActiveKey="1">
                      <TabPane tab="Tab 1" key="1">
                        No types found
                      </TabPane>
                    </Tabs>
                  );
                }

                return (
                  <Tabs
                    style={{ marginTop: "2rem" }}
                    defaultActiveKey={types[0]._id}
                  >
                    {types.map(type => {
                      const filterdData = data.filter(d => d.type === type._id);

                      return (
                        <TabPane tab={type.label} key={type._id}>
                          <EditableTable
                            rowKey="_id"
                            showHeader={false}
                            loading={isLoading}
                            dataSource={filterdData}
                            columns={columns}
                            onDelete={remove}
                            onSave={edit}
                          />
                        </TabPane>
                      );
                    })}
                  </Tabs>
                );
              }}
            </HorseTypeData>
          </div>
        );
      }}
    </DisciplineData>
  );
};

export default ColourManager;
