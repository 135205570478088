import { message } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import api from 'api';
import getAll from '../../getAll';

const DisciplineData = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [data, setData] = useState([]);

  const fetchData = useCallback(
    async (options = {}) => {
      setIsError(false);
      setIsLoading(true);

      if (props.useAWS) {
        try {
          const data = await getAll(
            'Discipline-y5qxqcfgvfhuligxdw6cz2oizy-production'
          );

          setData(data);
        } catch (error) {
          setIsError(true);
          console.error(error);
        }
      } else {
        try {
          const { data } = await api.get(`/Discipline??populate=type`, {
            ...options,
            params: {
              query: { deletedAt: { $exists: 0 } },
              sort: 'label',
            },
          });

          setData(data);
        } catch (error) {
          setIsError(true);
          console.error(error);
        }
      }

      setIsLoading(false);
    },
    [props.useAWS]
  );
  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const update = async (id, info, successMessage = 'Complete') => {
    if (!id) {
      return message.error('No ID found');
    }

    try {
      const { data } = await api.patch(`/Discipline/${id}`, {
        ...info,
      });

      message.success(successMessage);
      fetchData({ force: true });

      return data;
    } catch (e) {
      message.error(e.message);
    }
  };

  const remove = async (id) => {
    try {
      await api.delete(`/Discipline/${id}`);
      message.success('Sucessfully removed discipline');
      fetchData({ force: true });
    } catch (e) {
      message.error(e.message);
    }
  };

  const restore = async (id) => {
    await update(
      id,
      {
        deletedAt: null,
      },
      'Sucessfully restored discipline'
    );
  };

  const add = async (info) => {
    try {
      const { data } = await api.post('/Discipline', {
        ...info,
      });
      fetchData({ force: true });
      return data;
    } catch (e) {
      return message.error(e.message);
    }
  };

  const editLabel = async (values) => {
    const { _id, label } = values;
    await update(
      _id,
      {
        label: label,
      },
      'Sucessfully updated discipline'
    );
  };

  return props.children({
    isLoading,
    isError,
    data,
    remove,
    restore,
    add,
    edit: editLabel,
  });
};

export default DisciplineData;
