import {
  Avatar,
  Button,
  Col,
  Form,
  Input,
  Popconfirm,
  Popover,
  Row,
  Switch,
  Tag,
} from 'antd';

import GoogleMapReact from 'google-map-react';
import React from 'react';
import moment from 'moment';

class UserForm extends React.Component {
  state = {
    validateStatus: '',
    postCodeError: false,
    isBusiness: false,
    showSuspendUser: false,
    showDeleteUserPopOver: false,
    isSubscribed: false,
    isBilledOffline: false,
  };

  postCodeValidator = () => {
    const postcodePattern = /(GIR 0AA)|((([ABCDEFGHIJKLMNOPRSTUWYZ][0-9][0-9]?)|(([ABCDEFGHIJKLMNOPRSTUWYZ][ABCDEFGHKLMNOPQRSTUVWXY][0-9][0-9]?)|(([ABCDEFGHIJKLMNOPRSTUWYZ][0-9][ABCDEFGHJKSTUW])|([ABCDEFGHIJKLMNOPRSTUWYZ][ABCDEFGHKLMNOPQRSTUVWXY][0-9][ABEHMNPRVWXY])))) [0-9][ABDEFGHJLNPQRSTUWXYZ]{2})/;
    const postcode = this.props.form.getFieldValue('postcode');

    if (!postcodePattern.test(postcode.toUpperCase())) {
      this.setState({
        validateStatus: 'error',
        postCodeError: true,
      });
    } else {
      this.setState({
        validateStatus: 'success',
        postCodeError: false,
      });
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();
    // this.postCodeValidator();
    this.props.form.validateFields((err, values) => {
      if (!err && !this.state.postCodeError) {
        this.props.onUpdate(this.props.data._id, values);
      } else {
      }
    });
  };

  onSuspendUser = () => {
    const { _id } = this.props.data;
    this.props.onSuspendUser(_id);
  };
  onRestoreUser = () => {
    const { _id } = this.props.data;
    this.props.onRestoreUser(_id);
  };

  onResetPassword = () => {
    const { email } = this.props.data;
    this.props.onResetPassword(email);
  };

  onRemoveUser = () => {
    const { _id } = this.props.data;
    this.props.onRemoveUser(_id);
  };

  handleChangeBusiness = (v) => {
    this.setState({ isBusiness: v });
  };

  handleChangeSubscribed = (v) => {
    this.setState({ isSubscribed: v });
  };

  handleVisibleChange = (key) => {
    this.setState({ [key]: !this.state[key] });
  };

  cancel = (key) => {
    this.setState({ [key]: false });
  };

  render() {
    const {
      email,
      profile,
      createdAt,
      _id,
      deletedAt,
      emails,
      isBilledOffline,
      suspendedAt,
    } = this.props.data;
    const {
      firstName,
      lastName,
      img,
      coords,
      bio,

      isBusiness,
      businessName,
    } = profile || '';

    const { getFieldDecorator } = this.props.form;

    if (deletedAt) {
      return <h2>This user has been removed from Whickr</h2>;
    }

    const isVerified = emails && emails.length > 0 && emails[0].verified;

    return (
      <Form
        onSubmit={(e) => {
          this.handleSubmit(e);
        }}
      >
        <Row gutter={16}>
          <Col span={24} style={{ textAlign: 'left' }}>
            <div style={{ display: 'flex', marginBottom: '1.2rem' }}>
              <Avatar
                src={img}
                size={48}
                icon='user'
                style={{ marginRight: '2rem' }}
              />
              <div>
                <Row type='flex' align='middle'>
                  <h2
                    style={{
                      fontWeight: 800,
                      marginBottom: '-0.2rem',
                      marginRight: '2rem',
                    }}
                  >
                    {isBusiness ? businessName : firstName + ' ' + lastName}
                  </h2>

                  {isVerified ? (
                    <Tag color='green'>Verified</Tag>
                  ) : (
                    <Tag color='orange'>Not Verified</Tag>
                  )}
                </Row>

                <p style={{ color: '#707c80' }}>
                  Joined{' '}
                  {moment(createdAt).calendar(null, {
                    sameDay: '[Today]',
                    nextDay: '[Tomorrow]',
                    nextWeek: 'dddd',
                    lastDay: '[Yesterday]',
                    lastWeek: 'dddd',
                    sameElse: 'DD/MM/YYYY',
                  })}
                </p>
              </div>
            </div>
            <h4
              style={{
                marginBottom: '2rem',
                color: '#707c80',
                fontSize: '1rem',
              }}
            >
              {bio}
            </h4>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={{ span: 24 }} lg={{ span: 24 }}>
            <Row type='flex' justify='space-between'>
              <Form.Item label='Business'>
                {getFieldDecorator('isBusiness', {
                  initialValue: isBusiness || false,
                  valuePropName: 'checked',
                })(<Switch onChange={this.handleChangeBusiness} />)}
              </Form.Item>
              {/* Uncomment this when ready */}
              {/* <Form.Item label='Subscriber'>
                {getFieldDecorator('isSubscribed', {
                  initialValue: isSubscribed || false,
                  valuePropName: 'checked'
                })(<Switch onChange={this.handleChangeSubscribed} />)}
              </Form.Item> */}
            </Row>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 24 }}>
            <Row type='flex' justify='space-between'>
              <Form.Item label='Bill Offline'>
                {getFieldDecorator('isBilledOffline', {
                  initialValue: isBilledOffline || false,
                  valuePropName: 'checked',
                })(
                  <Switch
                    onChange={() => this.handleVisibleChange('isBilledOffline')}
                  />
                )}
              </Form.Item>
              {/* Uncomment this when ready */}
              {/* <Form.Item label='Subscriber'>
                {getFieldDecorator('isSubscribed', {
                  initialValue: isSubscribed || false,
                  valuePropName: 'checked'
                })(<Switch onChange={this.handleChangeSubscribed} />)}
              </Form.Item> */}
            </Row>
          </Col>
          {(isBusiness || this.state.isBusiness) && (
            <Col xs={{ span: 24 }} lg={{ span: 16 }}>
              <Form.Item label='Business Name'>
                {getFieldDecorator('profile.businessName', {
                  initialValue: businessName || '',
                  rules: [
                    {
                      message: 'Input the Business name.',
                    },
                    {
                      required: this.state.isBusiness,
                      message: 'Please input a Business name!',
                    },
                  ],
                })(<Input placeholder='Stable Name' size='large' />)}
              </Form.Item>
            </Col>
          )}
        </Row>
        <Row gutter={16}>
          <Col xs={{ span: 24 }} lg={{ span: 8 }}>
            <Form.Item label='First Name'>
              {getFieldDecorator('profile.firstName', {
                initialValue: firstName || '',
                rules: [
                  {
                    message: 'Input the first name.',
                  },
                  {
                    required: true,
                    message: 'Please input your first name!',
                  },
                ],
              })(<Input size='large' />)}
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 8 }}>
            <Form.Item label='Last Name'>
              {getFieldDecorator('profile.lastName', {
                initialValue: lastName || '',
                rules: [
                  {
                    message: 'Input the last name.',
                  },
                  {
                    required: true,
                    message: 'Please input your last name!',
                  },
                ],
              })(<Input size='large' />)}
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 16 }}>
            <Form.Item label='E-Mail'>
              {getFieldDecorator('email', {
                initialValue: email || '',
                rules: [
                  {
                    type: 'email',
                    message: 'Input a valid email.',
                  },
                  {
                    required: true,
                    message: 'Input the email',
                  },
                ],
              })(<Input size='large' />)}
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} lg={{ span: 24 }}>
            <Form.Item label='Bio'>
              {getFieldDecorator('profile.bio', {
                initialValue: bio || '',
              })(<Input.TextArea size='large' autosize />)}
            </Form.Item>
          </Col>
          <Col span={24}>
            {coords && (
              <div style={{ height: '400px', width: '100%' }}>
                <GoogleMapReact
                  bootstrapURLKeys={{
                    key: process.env.REACT_APP_GOOGLE_MAPS_API,
                  }}
                  defaultCenter={coords}
                  defaultZoom={11}
                />
              </div>
            )}
          </Col>
        </Row>

        <div style={{ marginTop: '2rem' }}>
          {_id ? (
            <Row>
              <Col span={8}>
                <Popconfirm
                  title='Are you sure you want to permanently delete this user?'
                  visible={this.state.showDeleteUserPopOver}
                  onVisibleChange={() =>
                    this.handleVisibleChange('showDeleteUserPopOver')
                  }
                  onConfirm={this.onRemoveUser}
                  onCancel={() => this.cancel('showDeleteUserPopOver')}
                  okText='Yes'
                  cancelText='No'
                >
                  <Button
                    size='large'
                    type='link'
                    onClick={this.onRemoveUser}
                    style={{ color: 'red' }}
                  >
                    Delete User
                  </Button>
                </Popconfirm>
                {suspendedAt ? (
                  <Button
                    style={{ marginRight: '2rem' }}
                    size='large'
                    type='link'
                    onClick={this.onRestoreUser}
                  >
                    Restore User
                  </Button>
                ) : (
                  <Popconfirm
                    title='Are you sure you want to suspend this user?'
                    visible={this.state.showSuspendUser}
                    onVisibleChange={() =>
                      this.handleVisibleChange('showSuspendUser')
                    }
                    onConfirm={this.onSuspendUser}
                    onCancel={() => this.cancel('showDeleteUserPopOver')}
                    okText='Yes'
                    cancelText='No'
                  >
                    <Button
                      style={{ marginRight: '2rem' }}
                      size='large'
                      type='link'
                    >
                      Suspend User
                    </Button>
                  </Popconfirm>
                )}
              </Col>
              <Col span={16} style={{ textAlign: 'right' }}>
                <Popover
                  content={
                    <div>
                      <p>
                        This will send an email to {email} asking them to reset
                        their password
                      </p>
                    </div>
                  }
                  title='WARNING!'
                  trigger='hover'
                >
                  <Button
                    style={{ marginRight: '2rem' }}
                    size='large'
                    type='secondary'
                    onClick={this.onResetPassword}
                  >
                    Reset Password
                  </Button>
                </Popover>

                <Button
                  disabled={deletedAt}
                  size='large'
                  type='primary'
                  htmlType='submit'
                  className='login-form-button'
                >
                  Update User
                </Button>
              </Col>
            </Row>
          ) : (
            <Button
              size='large'
              type='primary'
              htmlType='submit'
              className='login-form-button'
            >
              Create a new User
            </Button>
          )}
        </div>
      </Form>
    );
  }
}

export default Form.create()(UserForm);
