import React, { useState } from 'react';

import { Button, Col, Modal, Popconfirm, Result, Row, Table } from 'antd';
import { Link } from 'react-router-dom';
import Media from 'react-media';
import Title from 'Components/Title';

import './LandingPagesTable.css';
import LandingPageFormScene from 'scenes/LandingPagesScene/LandingPagesFormScene';

const AdvertsTable = ({
  data,
  isLoading,
  isError,

  discipline,

  removeLandingPage,
  getLandingPages,
  onSearch,
  advertCount,
}) => {
  const [statePagination] = useState();
  const [showModal, setshowModal] = useState(false);

  if (isError) {
    return (
      <Result
        status='500'
        title='Server Error'
        subTitle='Sorry, there was an error getting advert data'
      />
    );
  }

  const columns = [
    {
      title: 'Title',
      dataIndex: 'headerTitle',
      key: 'title',
      width: 100,
      render: (v, { id }) => {
        return (
          <b>
            <Link to={`/landing-pages/${id}`}>{v}</Link>
          </b>
        );
      },
    },

    {
      title: 'Paragraph',
      dataIndex: 'headerParagraph',
      key: 'paragraph',
      width: 100,
    },
    {
      title: 'Button Label',
      dataIndex: 'headerButtonLabel',
      key: 'buttonLabel',
      width: 100,
    },
    {
      title: 'Slug',
      dataIndex: 'slug',
      key: 'slug',
      width: 100,
      render: (v, { slug }) => {
        return (
          <a
            href={`https://whickr.com/collection/${slug}`}
            target='_blank'
            rel='noopener noreferrer'
          >
            {slug}
          </a>
        );
      },
    },

    {
      title: '',
      dataIndex: 'edit',
      key: 'edit',
      width: 100,

      render: (i, r) => (
        <div>
          <Link to={`landing-pages/${r.id}`}>
            <Button
              type='primary'
              shape='circle'
              icon='edit'
              ghost
              size='small'
              style={{ margin: '10px' }}
            />
          </Link>
          {!r.deletedAt && (
            <Popconfirm
              placement='topLeft'
              title='Remove Landing page?'
              onConfirm={() => removeLandingPage(r.id)}
              okText='Yes'
              cancelText='No'
            >
              <Button
                icon='delete'
                type='danger'
                shape='circle'
                ghost
                size='small'
              />
            </Popconfirm>
          )}
        </div>
      ),
    },
  ];

  const getResponsiveColumns = (smallScreen) =>
    columns.filter(({ hideOnSmall = false }) => !(smallScreen && hideOnSmall));

  return (
    <React.Fragment>
      <Modal
        width={820}
        title='Create Landing Page'
        footer={null}
        visible={showModal}
        onOk={() => setshowModal(false)}
        onCancel={() => setshowModal(false)}
      >
        <LandingPageFormScene
          getLandingPages={getLandingPages}
          onSubmit={() => {
            setshowModal(false);
          }}
        />
      </Modal>

      <Row gutter={8}>
        <Col xs={24} sm={24} md={24} lg={12} xl={14}>
          <Title title='Landing Pages' count={advertCount} />
        </Col>

        <Col xs={24} sm={24} md={24} lg={12} xl={10} align='right'>
          <Button
            size='large'
            onClick={() => setshowModal(true)}
            icon='plus'
            type='primary'
          >
            Create Landing Page
          </Button>
        </Col>
      </Row>

      <Media query='(max-width: 1470px)'>
        {(smallScreen) => {
          let useColumns = getResponsiveColumns(smallScreen);

          return (
            <Table
              rowClassName={(r, i) =>
                r.deletedAt ? 'removedAdvert' : r.soldAt && 'soldAdvert'
              }
              loading={isLoading}
              rowKey='_id'
              dataSource={data}
              columns={useColumns}
              pagination={statePagination}
              // onChange={(pagination, filters, sorter) =>
              //   handleTableChange(pagination, filters, sorter, type)
              // }
            />
          );
        }}
      </Media>
    </React.Fragment>
  );
};

export default AdvertsTable;
