import { Col, DatePicker, Empty, Result, Row, Skeleton } from 'antd';
import Card from 'Components/Card';
import MetricCard from 'Components/MetricCard';
import MetricData from 'Data/MetricData';
import React, { useState } from 'react';
import moment from 'moment';

const { RangePicker } = DatePicker;

const MetricsManager = () => {
  const [startDate, setStartDate] = useState(
    moment()
      .startOf('day')
      .subtract(1, 'days'),
  );
  const [endDate, setEndDate] = useState(
    moment()
      .endOf('day')
      .subtract(1, 'days'),
  );
  const onChange = (dates, dateStrings) => {
    setStartDate(dates[0]);
    setEndDate(dates[1]);
  };

  const disableDates = current => current && current > moment().endOf('day');

  return (
    <MetricData startDate={startDate} endDate={endDate}>
      {({ isLoading, isError, data }) => {
        // Loading state
        if (isLoading) {
          return (
            <React.Fragment>
              <Col xs={24} sm={24} md={12} lg={12} xl={8}>
                <Card>
                  <Skeleton active loading={isLoading} />
                </Card>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={8}>
                <Card>
                  <Skeleton active loading={isLoading} />
                </Card>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={8}>
                <Card>
                  <Skeleton active loading={isLoading} />
                </Card>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={8}>
                <Card>
                  <Skeleton active loading={isLoading} />
                </Card>
              </Col>
            </React.Fragment>
          );
        }

        //  Empty State
        if (!data.metrics && !isLoading) {
          return (
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Empty />
            </Col>
          );
        }

        // Error getting data
        if (isError) {
          return (
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Result
                status='500'
                title='Something went wrong getting the data '
              />
            </Col>
          );
        }

        return (
          <React.Fragment>
            <Row
              type='flex'
              justify='end'
              align='top'
              style={{ width: '100%' }}
            >
              <RangePicker
                style={{
                  marginRight: '30px',
                }}
                defaultValue={[startDate, endDate]}
                disabledDate={disableDates}
                size='large'
                ranges={{
                  Today: [moment().startOf('day'), moment()],
                  Yesterday: [
                    moment()
                      .startOf('day')
                      .subtract(1, 'days'),
                    moment()
                      .endOf('day')
                      .subtract(1, 'days'),
                  ],
                  'This Week': [moment().startOf('week'), moment()],
                  'This Month': [moment().startOf('month'), moment()],
                  'Past 30 Days': [moment().subtract(30, 'days'), moment()],
                }}
                format='DD/MM/YYYY'
                onChange={onChange}
              />
            </Row>
            <Row type='flex' justify='space-between' style={{ width: '100%' }}>
              {data.metrics &&
                data.metrics.map(metric => {
                  const {
                    label,
                    data: value,
                    chartData,
                    chartColour,
                    prefix,
                  } = metric;
                  return (
                    <Col
                      key={label}
                      xs={24}
                      sm={24}
                      md={12}
                      lg={12}
                      xl={4}
                      xxl={4}
                    >
                      <MetricCard
                        isLoading={isLoading}
                        isError={isError}
                        label={label}
                        value={value}
                        chartData={chartData}
                        chartColour={chartColour}
                        prefix={prefix}
                      />
                    </Col>
                  );
                })}
            </Row>
          </React.Fragment>
        );
      }}
    </MetricData>
  );
};

export default MetricsManager;
