import { useEffect, useState } from "react";
import api from "api";

const GenderData = props => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      setIsError(false);
      setIsLoading(true);

      try {
        const { data } = await await api.get(`/Gender`, {
          params: {
            sort: "label"
          }
        });

        setData(data);
      } catch (error) {
        setIsError(true);
        console.error(error);
      }

      setIsLoading(false);
    };

    fetchData();
  }, []);

  return props.children({
    isLoading,
    isError,
    data
  });
};

export default GenderData;
