import React, { Component } from 'react';

import { withRouter } from 'react-router-dom';

import { message } from 'antd';
import api from 'api';
import auth0Client from 'auth';

class Callback extends Component {
  async componentDidMount() {
    try {
      await auth0Client.handleAuthentication();

      const token = auth0Client.getIdToken();
      api.defaults.headers.common['Authorization'] = `Bearer ${token}`;

      this.props.history.replace('/dashboard');
    } catch (e) {
      if (e.error === 'unauthorized') {
        message.error('unauthorized');
        const url = `https://${process.env.REACT_APP_AUTH0_DOMAIN}/v2/logout`;
        window.location.href = url;

        this.props.history.replace('/');
      }
    }
  }

  render() {
    return <div />;
  }
}

export default withRouter(Callback);
