import React from 'react';

import { Route } from 'react-router-dom';
import { Spin } from 'antd';
import auth0Client from 'auth';

function SecuredRoute(props) {
  const { component: Component, path, checkingSession } = props;

  if (checkingSession) {
    return (
      <div
        style={{
          display: 'flex',
          height: '100vh',
          width: '100%',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Spin />
      </div>
    );
  }

  return (
    <Route
      path={path}
      render={() => {
        if (!auth0Client.isAuthenticated()) {
          auth0Client.signIn();
          return <div />;
        }
        return <Component match={props.computedMatch} />;
      }}
    />
  );
}

export default SecuredRoute;
