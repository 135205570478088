import React from 'react';

import { Link } from 'react-router-dom';
import { Table } from 'antd';
import AdvertsData from 'Data/AdvertsData';
import Card from 'Components/Card';
import Media from 'react-media';
import Title from 'Components/Title';
import moment from 'moment';

const DashboardRecentlySold = () => {
  const columns = [
    {
      title: 'Headline',
      dataIndex: 'headline',
      key: 'headline',
      width: 100,
      render: (advert, r) => {
        return (
          <Link to={`adverts/${r._id}`}>
            <b>{advert}</b>
          </Link>
        );
      }
    },

    {
      title: 'Date Sold',
      dataIndex: 'soldAt',
      hideOnSmall: true,
      key: 'soldAt',
      width: 100,
      render: date => {
        const d = moment(date).calendar(null, {
          sameDay: '[Today]',
          nextDay: '[Tomorrow]',
          nextWeek: 'dddd',
          lastDay: '[Yesterday]',
          lastWeek: 'dddd',
          sameElse: 'DD/MM/YYYY'
        });

        return `Sold ${d}`;
      }
    },

    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price',
      width: 100,
      hideOnSmall: true,
      render: i => {
        if (i) {
          const number = parseInt(i, 10);
          return number.toLocaleString('en', {
            style: 'currency',
            currency: 'GBP'
          });
        }
        return 'POA';
      }
    }
  ];
  const getResponsiveColumns = smallScreen =>
    columns.filter(({ hideOnSmall = false }) => !(smallScreen && hideOnSmall));
  return (
    <Card>
      <Title title='Latest 10 Adverts Sold' />
      <AdvertsData
        query={{
          $and: [{ soldAt: { $exists: true } }, { soldAt: { $ne: null } }]
        }}
        limit={10}
        sort={{ soldAt: -1 }}
        includeDeletedAdverts
      >
        {({ data, isLoading }) => {
          return (
            <Media query='(max-width: 780px)'>
              {smallScreen => {
                return (
                  <Table
                    showHeader={false}
                    loading={isLoading}
                    rowKey={'_id'}
                    dataSource={data}
                    columns={getResponsiveColumns(smallScreen)}
                    pagination={false}
                  />
                );
              }}
            </Media>
          );
        }}
      </AdvertsData>
    </Card>
  );
};

export default DashboardRecentlySold;
