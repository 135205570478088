import React, { useState } from 'react';

import { Button, Col, Icon, Input, Result, Row, Table, Tabs } from 'antd';
import { Link } from 'react-router-dom';
import Media from 'react-media';
import moment from 'moment';

import Title from 'Components/Title';
import UsersData from 'Data/UsersData';

const { TabPane } = Tabs;

const UsersManager = () => {
  const columns = [
    {
      title: '',
      dataIndex: 'emails[0].verified',
      key: 'emails',
      width: 3,
      render: (isVerified, r) => {
        return (
          <Icon
            type={isVerified ? 'check-circle' : 'close-circle'}
            theme='twoTone'
            twoToneColor={isVerified ? '#53d769' : 'orange'}
          />
        );
      },
    },
    {
      title: 'Name',
      dataIndex: 'profile',
      key: 'profile',
      width: 100,
      render: (profile, r) => {
        const { firstName, lastName } = profile || '';
        return (
          <Link to={`user/${r._id}`}>
            <b>
              {firstName} {lastName}
            </b>
          </Link>
        );
      },
    },
    {
      title: 'E-Mail',
      dataIndex: 'emails',
      key: 'email',
      width: 100,
      align: 'left',
      render: r => {
        return r[0].address;
      },
    },

    {
      title: 'Joined',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 100,
      align: 'left',
      sortDirections: ['descend', 'ascend'],

      sorter: (a, b) => {
        const aDate = new Date(a.createdAt);
        const bDate = new Date(b.createdAt);
        return aDate - bDate;
      },
      render: date => {
        return <span>{moment(date).format('MMMM Do YYYY')}</span>;
      },
    },
    {
      title: 'Adverts',
      dataIndex: 'adverts_count',
      key: 'adverts_count',
      width: 50,
      align: 'center',
      hideOnSmall: true,
    },

    {
      title: '',
      dataIndex: 'actions',
      key: 'actions',
      width: 50,
      align: 'center',

      render: (i, r) => {
        return (
          <Link to={`user/${r._id}`}>
            <Button
              type='primary'
              shape='circle'
              ghost
              size='small'
              icon='edit'
            />
          </Link>
        );
      },
    },
  ];

  const getResponsiveColumns = smallScreen =>
    columns.filter(({ hideOnSmall = false }) => !(smallScreen && hideOnSmall));

  const defaultQuery = { deletedAt: null };

  const [query, setQuery] = useState(defaultQuery);
  const [page, setPage] = useState(0);
  const [skip, setSkip] = useState(0);
  const [sort, setSort] = useState({ createdAt: -1 });

  function callback(key) {
    setPage(0);
    setSkip(0);
    if (key === 'users') {
      setQuery(defaultQuery);
    } else {
      const q = { suspendedAt: { $exists: true } };
      setQuery(q);
    }
  }

  const onChange = (pagination, filters, sorter) => {
    const { current } = pagination;
    const skip = 10 * current;
    const { order, field } = sorter;

    let sorterObject = {
      createdAt: -1,
    };

    if (field) {
      sorterObject = {
        [field]: order === 'ascend' ? 1 : -1,
      };
    }

    setPage(current);
    setSkip(skip);
    setSort(sorterObject);
  };

  return (
    <div>
      <UsersData query={query} limit={10} skip={skip} sort={sort}>
        {({ isLoading, isError, data: users, count, search }) => {
          if (isError) {
            return (
              <Result
                status='500'
                title='Error Getting Users'
                subTitle='Sorry, something went wrong getting users'
              />
            );
          }
          return (
            <React.Fragment>
              <Row>
                <Col span={16}>
                  <Title title='Users' count={count} />
                </Col>
                <Col span={8}>
                  <Input.Search
                    allowClear
                    onChange={e => {
                      if (!e.target.value) {
                        search(null);
                      }
                    }}
                    onSearch={search}
                    placeholder='Search name or email address'
                    size='large'
                  />
                </Col>
              </Row>
              <Media query='(max-width: 1100px)'>
                {smallScreen => {
                  return (
                    <Tabs defaultActiveKey='1' onChange={callback}>
                      <TabPane tab='Users' key='users'>
                        <Table
                          loading={isLoading}
                          rowKey='_id'
                          dataSource={users}
                          onChange={onChange}
                          columns={getResponsiveColumns(smallScreen)}
                          pagination={{
                            total: parseInt(count),
                            page,
                            pageSize: 100,
                          }}
                        />
                      </TabPane>
                      <TabPane tab='Suspended Users' key='suspended'>
                        <Table
                          loading={isLoading}
                          rowKey='_id'
                          dataSource={users}
                          onChange={onChange}
                          columns={getResponsiveColumns(smallScreen)}
                          pagination={{
                            total: parseInt(count),
                            page,
                            pageSize: 10,
                          }}
                        />
                      </TabPane>
                    </Tabs>
                  );
                }}
              </Media>
            </React.Fragment>
          );
        }}
      </UsersData>
    </div>
  );
};

export default UsersManager;
