import { Col, Row } from 'antd';
import Card from 'Components/Card';
import ColourManager from 'Components/ColourManager';
import DisciplinesManager from 'Components/DisciplinesManager';
import React from 'react';

const SettingsScene = () => {
  return (
    <Row gutter={8}>
      <Col xs={24} sm={24} md={24} lg={12} xl={12}>
        <Card>
          <ColourManager />
        </Card>
      </Col>
      <Col xs={24} sm={24} md={24} lg={12} xl={12}>
        <Card>
          <DisciplinesManager />
        </Card>
      </Col>
    </Row>
  );
};

export default SettingsScene;
