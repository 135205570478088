import React, { Suspense, useEffect, useState } from 'react';

import './App.css';
import AdvertScene from './scenes/AdvertScene';
import AdvertsScene from './scenes/Adverts';
import ColourManager from './Components/ColourManager';
import MainLayout from './layouts/MainLayout';

import UserScene from './scenes/UserScene';
import UsersScene from './scenes/UsersScene';

import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';

import CallBack from 'Components/CallBack';
import DashboardScene from 'scenes/DashboardScene';
import ErrorBoundary from 'Components/ErrorBoundary';
import Logout from 'Components/Logout';
import SecuredRoute from 'Components/SecuredRoute';
import SettingsScene from 'scenes/SettingsScene';

import LandingPagesScene from 'scenes/LandingPagesScene';

import LandingPagesFormScene from 'scenes/LandingPagesScene/LandingPagesFormScene';
import auth0Client from 'auth';

require('@uppy/core/dist/style.css');
require('@uppy/dashboard/dist/style.css');

const Routes = (props) => {
  return (
    <MainLayout>
      <ErrorBoundary>
        <Switch>
          <Route exact path='/logout' component={Logout} />
          <SecuredRoute exact path='/' component={DashboardScene} {...props} />
          <SecuredRoute
            exact
            path='/adverts'
            component={AdvertsScene}
            {...props}
          />
          <SecuredRoute
            exact
            path='/adverts/:id'
            component={AdvertScene}
            {...props}
          />
          <SecuredRoute
            exact
            path='/colours'
            component={ColourManager}
            {...props}
          />
          <SecuredRoute
            exact
            path='/user/:id'
            component={UserScene}
            {...props}
          />
          <SecuredRoute exact path='/users' component={UsersScene} {...props} />
          <SecuredRoute
            exact
            path='/dashboard'
            component={DashboardScene}
            {...props}
          />
          <SecuredRoute
            exact
            path='/settings'
            component={SettingsScene}
            {...props}
          />
          <SecuredRoute
            exact
            path='/landing-pages'
            component={LandingPagesScene}
            {...props}
          />
          <SecuredRoute
            exact
            path='/landing-pages/:id'
            component={LandingPagesFormScene}
            {...props}
          />
        </Switch>
      </ErrorBoundary>
    </MainLayout>
  );
};

const App = () => {
  const [checkingSession, setCheckingSession] = useState(true);

  const siletAuth = async () => {
    try {
      await auth0Client.silentAuth();
    } catch (e) {
      console.error(e.error);
    }
    setCheckingSession(false);
  };

  useEffect(() => {
    siletAuth();
  }, []);

  return (
    <Suspense fallback={<span>Loading...</span>}>
      <Router>
        <Route exact path='/callback' component={CallBack} />
        <Routes checkingSession={checkingSession} />
      </Router>
    </Suspense>
  );
};

export default App;
