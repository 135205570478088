import { useEffect, useState } from 'react';
import api from 'api';
import getAll from '../../getAll';

const SaleTypeData = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      setIsError(false);
      setIsLoading(true);

      try {
        if (props.useAWS) {
          const data = await getAll(
            'SaleType-y5qxqcfgvfhuligxdw6cz2oizy-production'
          );
          setData(data);
        } else {
          const { data } = await api.get(`/SaleType`, {
            params: {
              sort: 'label',
              useAWS: props.useAWS,
            },
          });

          setData(data);
        }
      } catch (error) {
        setIsError(true);
        console.error(error);
      }

      setIsLoading(false);
    };

    fetchData();
  }, [props.useAWS]);

  return props.children({
    isLoading,
    isError,
    data,
  });
};

export default SaleTypeData;
