import React from 'react';

import { Badge, Button } from 'antd';
import styled from 'styled-components';

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
`;

const Text = styled.h2`
  margin-top: 0.5rem;
  margin-right: 1rem;
  font-weight: 800;
`;

const Title = (props) => {
  return (
    <TitleContainer>
      <Text>{props.title}</Text>
      <Badge
        showZero
        overflowCount={9999999}
        count={props.count}
        style={{
          backgroundColor: '#fff',
          color: '#999',
          boxShadow: '0 0 0 1px #d9d9d9 inset',
          marginRight: '1.2rem',
        }}
      />

      {props.icon && (
        <Button
          type='primary'
          shape='circle'
          size='small'
          icon={props.icon || null}
          onClick={props.onClick}
        />
      )}
    </TitleContainer>
  );
};

export default Title;
