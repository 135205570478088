// @flow
/* eslint-disable no-unused-vars */

import { withRouter } from 'react-router-dom';
import React from 'react';

import {
  Button,
  Checkbox,
  Col,
  Form,
  Icon,
  Input,
  InputNumber,
  Popconfirm,
  Radio,
  Row,
  Select,
  Spin,
  Tag,
  message,
} from 'antd';

import 'react-google-places-autocomplete/dist/assets/index.css';

import Card from 'Components/Card';
import Geocode from 'react-geocode';
import GoogleMapReact from 'google-map-react';
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
} from 'react-google-places-autocomplete';
import _ from 'lodash';

import styled from 'styled-components';

import DisciplineData from 'Data/DisciplineData';

import SaleTypeData from 'Data/SaleTypeData';

import '../../video.css';

import getAll from '../../getAll';
import removeItem from '../../removeItem';

var uuid = require('uuid');

var AWS = require('aws-sdk');

AWS.config.update({
  region: 'eu-west-2',
  credentials: {
    accessKeyId: process.env.REACT_APP_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_SECRET_KEY,
  },
});

var docClient = new AWS.DynamoDB.DocumentClient();

const FormItem = Form.Item;

const { TextArea } = Input;
const { Option } = Select;
const { REACT_APP_GEOCODE_API } = process.env;

// set Google Maps Geocoding API for purposes of quota management. Its optional but recommended.
Geocode.setApiKey(REACT_APP_GEOCODE_API);

const Title = styled.h2`
  font-weight: 800;
  display: inline-block;
`;
const HORSE_TYPE_ID = '5ac0973a-2239-4048-a953-f812bc74d927';

class LandingPageFormScene extends React.Component {
  _isMounted = false;

  state = {
    landingPage: {},
    error: false,
    horseType: '5c98fcbc48893164f136e649',
    location: '',
    loading: true,
    saleTypeId: '',
    typeId: 'both',
    isPOA: false,
    isFree: false,
  };

  componentDidMount() {
    this._isMounted = true;

    const { params } = this.props.match || {};

    if (params && params.id && this._isMounted) {
      this.getLandingPageDetails(params.id);
    } else {
      this.setState({ loading: false });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  getLandingPageDetails = async (id) => {
    try {
      const data = await getAll(
        'LandingPage-y5qxqcfgvfhuligxdw6cz2oizy-production'
      );

      const landingPage = _.find(data, { id });

      if (this._isMounted) {
        if (landingPage) {
          this.setState({
            landingPage,
            saleTypeId:
              landingPage.saleTypeId === '80b50ee5-0f84-48e7-92f1-65793a48d597'
                ? 'Sale'
                : 'Loan',
            users: [landingPage.userId],
            loading: false,
            disciplines: landingPage.disciplines,
            typeId: landingPage.typeId || 'both',
          });
        }
      }
    } catch (e) {
      console.log('e', e);
      if (this._isMounted) {
        this.setState({ error: true });
      }
    }
  };

  handleUpdate = (e) => {
    e && e.preventDefault();
    const {
      landingPage: { id },
    } = this.state;

    this.props.form.validateFieldsAndScroll(async (err, values) => {
      // const media = [];
      if (err) {
        console.error(err);
        return message.warning('Please check the form');
      }

      if (!id) {
        return message.error('Could not find ID of the advert', 20);
      }
      const now = new Date().toISOString();
      let Item = Object.assign(values, {
        disciplines: this.state.disciplines,
        typeId: this.state.typeId,

        updatedAt: now,
      });

      let expressionAttributeNames = {};

      let updateExpression = 'SET ';

      if (!this.locationRef.input.value) {
        updateExpression =
          'REMOVE #exactCoords, #coords, #formattedLocation, #location, #isAdminLevel1 SET ';

        expressionAttributeNames['#exactCoords'] = 'exactCoords';
        expressionAttributeNames['#coords'] = 'coords';
        expressionAttributeNames['#formattedLocation'] = 'formattedLocation';
        expressionAttributeNames['#location'] = 'location';
        expressionAttributeNames['#isAdminLevel1'] = 'isAdminLevel1';
        delete Item.exactCoords;
        delete Item.coords;
        delete Item.formattedLocation;
        delete Item.location;
        delete Item.isAdminLevel1;
      } else {
        if (this.state.landingPage.location) {
          Item = {
            ...Item,
            exactCoords: this.state.landingPage.exactCoords,
            coords: this.state.landingPage.coords,
            formattedLocation: this.state.landingPage.formattedLocation,
            location: this.state.landingPage.location,
            isAdminLevel1: this.state.landingPage.isAdminLevel1,
          };
        }
      }

      let expressionAttributeValues = {};

      if (
        !Item.isPOA ||
        Item.saleTypeId !== '80b50ee5-0f84-48e7-92f1-65793a48d597'
      ) {
        Item.isPOA = false;
      }

      Item.updatedAt = new Date().toISOString();

      const keys = Object.keys(Item);
      for (const key of keys) {
        if (Item[key] !== undefined) {
          expressionAttributeNames[`#${key}`] = key;
        }
        if (key === 'disiplines') {
          updateExpression += `disciplines=:disciplines,`;
          expressionAttributeValues[`:disciplines`] = AWS.StringSlice(
            Item[key]
          );
        } else if (Item[key] !== undefined) {
          let expressionKey = key;

          updateExpression += `#${expressionKey}=:${expressionKey},`;

          expressionAttributeValues[`:${expressionKey}`] = Item[key];
        }
      }

      updateExpression = updateExpression.slice(0, -1);

      var params = {
        TableName: 'LandingPage-y5qxqcfgvfhuligxdw6cz2oizy-production',
        Key: {
          id: id,
        },
        UpdateExpression: updateExpression,
        ExpressionAttributeNames: expressionAttributeNames,
        ExpressionAttributeValues: expressionAttributeValues,
        ReturnValues: 'UPDATED_NEW',
      };

      docClient.update(params, (err, data) => {
        if (err) {
          console.error(
            'Unable to updatde! item. Error JSON:',
            JSON.stringify(err, null, 2)
          );
        } else {
          message.success('Updated Landing page');
          this.props.history.push('/landing-pages');
        }
      });
    });
  };

  handleSubmit = (e) => {
    e && e.preventDefault();

    if (!this.state.postcodeLoading) {
      this.props.form.validateFieldsAndScroll((err, values) => {
        if (!err && !this.state.postcodeError) {
          const now = new Date().toISOString();

          let Item = Object.assign(values, {
            id: uuid.v4(),
            disciplines: this.state.disciplines,
            typeId: this.state.typeId,
            createdAt: now,
            updatedAt: now,
          });

          if (this.state.landingPage.exactCoords) {
            Item = {
              ...Item,
              exactCoords: this.state.landingPage.exactCoords,
              coords: this.state.landingPage.coords,
              formattedLocation: this.state.landingPage.formattedLocation,
              location: this.state.landingPage.location,
              isAdminLevel1: this.state.landingPage.isAdminLevel1,
            };
          }

          var params = {
            TableName: 'LandingPage-y5qxqcfgvfhuligxdw6cz2oizy-production',
            Item,
          };

          docClient.put(params, (err, data) => {
            if (err) {
              console.error(
                'Unable to add item. Error JSON:',
                JSON.stringify(err, null, 2)
              );
            } else {
              message.success('Created landing page');
              this.props.getLandingPages();
              this.props.onSubmit();
            }
          });
        }
      });
    }
  };

  getCoords = ({ place_id }) => {
    if (!place_id) {
      return message.error('No place ID found');
    }

    geocodeByPlaceId(place_id)
      .then((response) => {
        const { formatted_address, geometry } = response[0];
        const locationData = response[0];

        const lat = geometry.location.lat();
        const lon = geometry.location.lng();
        this.coords = { lat, lon };
        this.location = formatted_address;

        let locationDisplay = 'Unkown';


        const isAdminLevel1 = !!_.find(locationData.address_components, (o) =>
              o.types.includes('administrative_area_level_1')
            );

        let target = _.find(locationData.address_components, (o) =>
          o.types.includes('postal_town')
        );
        const country = _.find(locationData.address_components, (o) =>
          o.types.includes('country')
        );
        if (!target) {
          target = _.find(locationData.address_components, (o) =>
            o.types.includes('administrative_area_level_2')
          );
        }
        if (!target) {
          target = _.find(locationData.address_components, (o) =>
            o.types.includes('administrative_area_level_1')
          );
        }
        if (!target) {
          target = _.find(locationData.address_components, (o) =>
            o.types.includes('country')
          );
        }

        if (target) {
          let queryName = target.long_name;
          if (
            target.long_name.substr(-country.short_name.length + 2) !==
            `, ${country.short_name}`
          ) {
            queryName = `${target.long_name}, ${country.short_name}`;
          }
          locationDisplay = target.long_name;
          Geocode.fromAddress(queryName)
            .then((json) => {
              const {
                geometry: { location: cityLocation },
              } = json.results[0];

              
              

              const landingPageUpdated = Object.assign(this.state.landingPage, {
                exactCoords: { lat, lon },
                coords: cityLocation,
                location: formatted_address,
                formattedLocation: locationDisplay,
                isAdminLevel1 
              });

              this.setState({ landingPage: landingPageUpdated });
            })
            .catch((e) => {
              console.error(e);
              message.error('Could not geocode');
            });
        }
      })
      .catch((e) => {
        console.log(e);
        message.error('Could not geocode by placeId');
      });
  };

  handlePOACheckbox = (e) => {
    this.setState({ POA: e.target.checked });
  };

  handleFreeCheckbox = (e) => {
    this.setState({ isFree: e.target.checked });
  };

  handleDisciplineChange = (newDisciplines) => {
    this.setState({ disciplines: newDisciplines });
  };
  handleTypeIdChange = (newType) => {
    console.log('this.form',this.form);
    this.props.form.setFieldsValue({'disciplines': []});
    this.setState({ typeId: newType, disciplines: [] });
  };

  removeLandingPage = async () => {
    const {
      params: { id },
    } = this.props.match || {};
    await removeItem('LandingPage-y5qxqcfgvfhuligxdw6cz2oizy-production', id);
    this.props.history.push('/landing-pages');
  };

  render() {
    const {
      form: { getFieldDecorator, setFieldsValue },
    } = this.props;

    const { error, landingPage, newLocation, loading } = this.state;

    if (error) {
      return (
        <div>
          <h2>Error</h2>
        </div>
      );
    }

    if (loading) {
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            minHeight: '100vh',
          }}
        >
          <Spin />
        </div>
      );
    }

    const style = {
      formItem: { marginBottom: '1.7em', width: '100%' },
    };
    const formItemLayout = {
      labelCol: { span: 24 },
      wrapperCol: { span: 24 },
    };

    const {
      id,
      slug,
      headerTitle,
      headerParagraph,
      headerButtonLabel,
      footerTitle,
      footerParagraph,
      footerButtonLabel,
      ageMin,
      ageMax,

      heightMin,
      heightMax,
      location,

      saleTypeId,
      priceMin,
      priceMax,

      isPOA,

      exactCoords,
      formattedLocation,
    } = landingPage;

    const offsetLayout = {
      sm: {
        offset: 0,
      },
      md: {
        offset: 0,
      },
      xl: {
        offset: 0,
      },
      xxl: {
        offset: 0,
      },
    };

    const offsetLayoutHalf = {
      sm: {
        span: 12,
      },
      md: {
        span: 12,
      },
      xl: {
        span: 12,
      },
      xxl: {
        span: 12,
      },
    };

    let form = (
      <Form
        layout='inline'
        onSubmit={id ? this.handleUpdate : this.handleSubmit}
        ref={(ref) => {
          this.form = ref;
        }}
      >
        <Row gutter={16}>
          <Col {...offsetLayout}>
            <FormItem
              style={style.formItem}
              {...formItemLayout}
              label='Header Title'
            >
              {getFieldDecorator('headerTitle', {
                initialValue: headerTitle,
                rules: [
                  {
                    required: true,
                    message: 'Please provide a header title',
                    whitespace: true,
                  },
                ],
              })(
                <Input
                  placeholder='A great horse that will never let you down'
                  size='large'
                />
              )}
            </FormItem>
          </Col>

          <Col {...offsetLayout}>
            <FormItem
              style={style.formItem}
              {...formItemLayout}
              label='Header Paragraph'
            >
              {getFieldDecorator('headerParagraph', {
                initialValue: headerParagraph || '',
                rules: [
                  {
                    required: true,
                    message: 'Please provide a paragraph',
                  },
                ],
              })(
                <TextArea
                  placeholder='An average height male that loves to be by your side.'
                  size='large'
                  style={{ height: '200px', fontSize: '16px' }}
                />
              )}
            </FormItem>
          </Col>

          <Col {...offsetLayout}>
            <FormItem
              style={style.formItem}
              {...formItemLayout}
              label='Header Button Label'
            >
              {getFieldDecorator('headerButtonLabel', {
                initialValue: headerButtonLabel,
                rules: [
                  {
                    required: false,
                    whitespace: true,
                  },
                ],
              })(<Input placeholder='Looking for more horses?' size='large' />)}
            </FormItem>
          </Col>

          <Col {...offsetLayout}>
            <FormItem
              style={style.formItem}
              {...formItemLayout}
              label='Footer Title'
            >
              {getFieldDecorator('footerTitle', {
                initialValue: footerTitle,
              })(
                <Input
                  placeholder='A great horse that will never let you down'
                  size='large'
                />
              )}
            </FormItem>
          </Col>

          <Col {...offsetLayout}>
            <FormItem
              style={style.formItem}
              {...formItemLayout}
              label='Footer Paragraph'
            >
              {getFieldDecorator('footerParagraph', {
                initialValue: footerParagraph || '',
              })(
                <TextArea
                  placeholder='An average height male that loves to be by your side.'
                  size='large'
                  style={{ height: '200px', fontSize: '16px' }}
                />
              )}
            </FormItem>
          </Col>

          <Col {...offsetLayout}>
            <FormItem
              style={style.formItem}
              {...formItemLayout}
              label='Footer Button Label'
            >
              {getFieldDecorator('footerButtonLabel', {
                initialValue: footerButtonLabel,
              })(<Input placeholder='Looking for more horses?' size='large' />)}
            </FormItem>
          </Col>

          <Col {...offsetLayout}>
            <FormItem style={style.formItem} {...formItemLayout} label='Slug'>
              {getFieldDecorator('slug', {
                initialValue: slug,
                rules: [
                  {
                    required: true,
                    message: 'Please provide a slug',
                    whitespace: true,
                  },
                ],
              })(<Input placeholder='amazing-horses' size='large' />)}
            </FormItem>
          </Col>

          <Col {...offsetLayout}>
            <FormItem
              style={style.formItem}
              {...formItemLayout}
              label='Location'
              hasFeedback={this.state.postcodeLoading}
              validateStatus={this.state.postcodeError ? 'error' : 'validating'}
              help={this.state.postcodeError}
            >
              {getFieldDecorator('location', {
                initialValue: location,
                setFieldsValue: location || '',
                rules: [
                  {
                    required: false,
                    message: 'Please provide a location',
                  },
                ],
              })(
                <>
                  <GooglePlacesAutocomplete
                    placeholder='Barry, Cardiff'
                    apiKey={'AIzaSyADDYPO_psXyVWE427UAP0SW7zK2p1QCHk'}
                    initialValue={location}
                    renderInput={(props) => (
                      <Input
                        prefix={
                          <Icon
                            type='environment'
                            style={{ color: 'rgba(0,0,0,.25)' }}
                          />
                        }
                        {...props}
                        style={{ width: '100%' }}
                        ref={(ref) => {
                          this.locationRef = ref;
                        }}
                      />
                    )}
                    autocompletionRequest={{
                      componentRestrictions: {
                        country: ['uk', 'ie', 'gg', 'ge', 'im'],
                      },
                    }}
                    style={{ width: '100%' }}
                    onSelect={this.getCoords}
                  />
                </>
              )}
            </FormItem>
          </Col>

          <Col {...offsetLayoutHalf}>
            <FormItem
              style={style.formItem}
              {...formItemLayout}
              label='Age (Min)'
            >
              {getFieldDecorator('ageMin', {
                initialValue: ageMin,
                rules: [
                  {
                    required: false,
                    message: 'Please provide a min age',
                  },
                ],
              })(
                <InputNumber
                  style={{ width: '100%' }}
                  min={0}
                  placeholder='5'
                  size='large'
                />
              )}
            </FormItem>
          </Col>
          <Col {...offsetLayoutHalf}>
            <FormItem
              style={style.formItem}
              {...formItemLayout}
              label='Age (Max)'
            >
              {getFieldDecorator('ageMax', {
                initialValue: ageMax,
                rules: [
                  {
                    required: false,
                    message: 'Please provide a max age',
                  },
                ],
              })(
                <InputNumber
                  style={{ width: '100%' }}
                  min={0}
                  placeholder='5'
                  size='large'
                />
              )}
            </FormItem>
          </Col>

          <Col {...offsetLayoutHalf}>
            <FormItem
              style={style.formItem}
              {...formItemLayout}
              label='Height (Min)'
            >
              {getFieldDecorator('heightMin', {
                initialValue: heightMin,
                rules: [
                  {
                    required: false,
                    message: 'Please provide a min height',
                  },
                ],
              })(
                <InputNumber
                  style={{ width: '100%' }}
                  min={0}
                  max={40}
                  step={0.1}
                  placeholder='5.1'
                  size='large'
                />
              )}
            </FormItem>
          </Col>
          <Col {...offsetLayoutHalf}>
            <FormItem
              style={style.formItem}
              {...formItemLayout}
              label='Height (Max)'
            >
              {getFieldDecorator('heightMax', {
                initialValue: heightMax,
                rules: [
                  {
                    required: false,
                    message: 'Please provide a max height',
                  },
                ],
              })(
                <InputNumber
                  style={{ width: '100%' }}
                  min={0}
                  max={40}
                  step={0.1}
                  placeholder='5.1'
                  size='large'
                />
              )}
            </FormItem>
          </Col>

          <Col {...offsetLayoutHalf}>
            <FormItem
              style={style.formItem}
              {...formItemLayout}
              label='Price (Min)'
            >
              {getFieldDecorator('priceMin', {
                initialValue: priceMin,
                rules: [
                  {
                    required: false,
                    message: 'Please provide a price',
                  },
                ],
              })(
                <InputNumber
                  style={{ width: '100%' }}
                  placeholder='10000'
                  prefix={
                    <Icon type='pound' style={{ color: 'rgba(0,0,0,.25)' }} />
                  }
                  size='large'
                />
              )}
            </FormItem>
          </Col>
          <Col {...offsetLayoutHalf}>
            <FormItem
              style={style.formItem}
              {...formItemLayout}
              label='Price (Max)'
            >
              {getFieldDecorator('priceMax', {
                initialValue: priceMax,
                rules: [
                  {
                    required: false,
                    message: 'Please provide a price',
                  },
                ],
              })(
                <InputNumber
                  style={{ width: '100%' }}
                  placeholder='20000'
                  prefix={
                    <Icon type='pound' style={{ color: 'rgba(0,0,0,.25)' }} />
                  }
                  size='large'
                />
              )}
            </FormItem>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col {...offsetLayout}>
            <SaleTypeData useAWS>
              {({ data }) => {
                return (
                  <FormItem
                    style={style.formItem}
                    {...formItemLayout}
                    label='Sale Type'
                  >
                    {getFieldDecorator('saleTypeId', {
                      initialValue: saleTypeId,
                    })(
                      <Radio.Group buttonStyle='solid' size='large'>
                        {data &&
                          data.length > 0 &&
                          data.map((v) => {
                            return (
                              <Radio.Button
                                value={v.id}
                                key={v.id}
                                onChange={(e) => {
                                  this.setState({
                                    saleTypeId: v.label,
                                  });
                                }}
                              >
                                {v.label}
                              </Radio.Button>
                            );
                          })}
                        {this.state.saleTypeId === 'Sale' && (
                          <Form.Item
                            style={{ marginLeft: 20 }}
                            label='Include POA?'
                          >
                            {getFieldDecorator('isPOA', {
                              initialValue: isPOA || false,
                            })(
                              <Checkbox
                                defaultChecked={isPOA ? isPOA : false}
                              />
                            )}
                          </Form.Item>
                        )}
                      </Radio.Group>
                    )}
                  </FormItem>
                );
              }}
            </SaleTypeData>
          </Col>
          <Col {...offsetLayout}>
            <Form.Item
              name='typeId'
              label='Type'
              {...formItemLayout}
              style={{ minWidth: '100%' }}
            >
              <Select
                size={'large'}
                placeholder='Select One'
                onChange={this.handleTypeIdChange}
                value={this.state.typeId}
              >
                <Option key={'both'} value={'both'}>
                  Both
                </Option>
                <Option
                  key={'horse'}
                  value={'5ac0973a-2239-4048-a953-f812bc74d927'}
                >
                  Horse
                </Option>
                <Option
                  key={'pony'}
                  value={'5ae802e7-5b1b-4818-a67e-ecdf8e70fe88'}
                >
                  Pony
                </Option>
              </Select>
            </Form.Item>
          </Col>
          <br />
          <Col {...offsetLayout}>
            <DisciplineData useAWS>
              {({ data }) => {

                let disciplineOptions = [];
                let horseOptions = [];
                let ponyOptions = [];

                data.forEach((option) => {
                  let optionItem = (
                    <Option key={option.id} value={option.id}>
                      {option.label}
                    </Option>
                  );
                  
                  if (this.state.typeId === 'both') {
                    if (option.type === HORSE_TYPE_ID) {
                       optionItem = (
                        <Option key={option.id} value={option.id}>
                          {option.label} (Horse)
                        </Option>
                      );
                      horseOptions.push(optionItem);
                    } else {
                       optionItem = (
                        <Option key={option.id} value={option.id}>
                          {option.label} (Pony)
                        </Option>
                      );
                      ponyOptions.push(optionItem);
                    }
                  } else {
                    if (option.type === this.state.typeId) {
                      disciplineOptions.push(optionItem);
                    }
                  }
                });
                if (this.state.typeId === 'both') {
                  disciplineOptions = [
                      <Select.OptGroup label='Horse'>
                        {horseOptions}
                      </Select.OptGroup>,
                      <Select.OptGroup label='Pony'>
                        {ponyOptions}
                      </Select.OptGroup>
                  ];

                  
                }

                return (
                  <Form.Item
                    name='disciplines'
                    label='Disciplines'
                    {...formItemLayout}
                    style={{ minWidth: '100%' }}
                    
                  >
                    <Select
                      placeholder='Select'
                      mode='tags'
                      value={this.state.disciplines}
                      onChange={(val) => {
                        this.setState({disciplines: val})
                      }}
                      tagRender={(props) => {

                        if (data && props.value) {
                          let typeId = _.find(data, {
                            id: props.value,
                          });

                          if(typeId) {
                            typeId = typeId.typeId;
                          }

                          const { label, value, closable, onClose } = props;
                          const onPreventMouseDown = (event) => {
                            event.preventDefault();
                            event.stopPropagation();
                          };

                          return (
                            <Tag
                              onMouseDown={onPreventMouseDown}
                              closable={closable}
                              onClose={onClose}
                              style={{ marginRight: 3 }}
                            >
                              {`${props.label} (${
                                typeId === HORSE_TYPE_ID ? 'Horse' : 'Pony'
                              })`}
                            </Tag>
                          );
                        }
                      }}
                    >
                      {disciplineOptions}
                    </Select>
                  </Form.Item>
                );
              }}
            </DisciplineData>
          </Col>
        </Row>

        <Row>
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'flex-end',
            }}
          >
            {id ? (
              <React.Fragment>
                <Popconfirm
                  placement='topLeft'
                  title='Are you sure?'
                  onConfirm={this.removeLandingPage}
                  okText='Yes'
                  cancelText='No'
                >
                  <Button
                    icon={'delete'}
                    size='large'
                    type={'danger'}
                    ghost
                    style={{
                      marginRight: '2rem',
                      marginTop: '2rem',
                      marginBottom: '2rem',
                    }}
                  >
                    'Remove Landing Page'
                  </Button>
                </Popconfirm>

                <Button
                  icon='form'
                  size='large'
                  type='primary'
                  htmlType='submit'
                  className='login-form-button'
                  style={{ marginTop: '2rem', marginBottom: '2rem' }}
                >
                  Update Landing Page
                </Button>
              </React.Fragment>
            ) : (
              <Button
                size='large'
                type='primary'
                htmlType='submit'
                style={{
                  marginTop: '2rem',
                  marginBottom: '2rem',
                }}
              >
                Create New Landing Page
              </Button>
            )}
          </div>
        </Row>
      </Form>
    );

    if (id) {
      return (
        <Row
          style={{
            display: 'flex',
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Col
            span={16}
            style={{
              maxWidth: 1200,
            }}
          >
            <Card>
              <Title>Update Landing Page</Title>
              {form}
            </Card>
          </Col>
        </Row>
      );
    } else {
      return form;
    }
  }
}

export default withRouter(Form.create()(LandingPageFormScene));
