import { useEffect, useState } from 'react';

import api from 'api';

const TransactionsData = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [data, setData] = useState([]);
  const [transactionCount, setTransactionCount] = useState(0);

  const getAdverts = async (
    options,
    url = '/Transaction?populate=[{"path": "advertId"}]'
  ) => {
    setIsError(false);
    setIsLoading(true);

    try {
      const { data } = await api.get(url, options);
      const {
        data: { count },
      } = await api.get('Transaction/count', options);

      setTransactionCount(count);
      setData(data);
    } catch (error) {
      setIsError(true);
      console.error(error);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    let query = {
      ...props.query,
    };

    if (!props.includeDeletedAdverts) {
      query = {
        $or: [{ deletedAt: { $exists: false } }, { deletedAt: null }],
        ...props.query,
      };
    }

    const options = {
      force: true,
      params: {
        query: query,
        sort: props.sort || { createdAt: -1 },
        limit: props.limit || 100,
      },
    };

    getAdverts(options);
  }, [props.limit, props.query, props.sort, props.includeDeletedAdverts]);

  const count = async (options = {}) => {
    const { data } = await api.get('Transaction/count', options);
    setTransactionCount(data);
    return data;
  };

  return props.children({
    isLoading,
    isError,
    data,

    getAdverts,
    count,
    advertCount: transactionCount,
  });
};

export default TransactionsData;
