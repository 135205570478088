import React, { useState } from "react";

import { Animated } from "react-animated-css";
import { Col, Row } from "antd";

import AdvertForm from "./AdvertForm";
import Card from "Components/Card";
import ColourManager from "Components/ColourManager";

const AdvertScene = props => {
  const [shouldShowColours, setShowColours] = useState(false);

  return (
    <Row
      style={{
        display: "flex",
        flex: 1,
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <Col
        span={16}
        style={{
          maxWidth: 1200
        }}
      >
        <Card>
          <AdvertForm {...props} setShowColours={setShowColours} />
        </Card>
      </Col>
      <Col offset={1}>
        {shouldShowColours && (
          <Animated
            animationIn="bounceInRight"
            animationOut="fadeOut"
            isVisible={shouldShowColours}
          >
            <Card>
              <ColourManager />
            </Card>
          </Animated>
        )}
      </Col>
    </Row>
  );
};

export default AdvertScene;
