import React, { useState } from 'react';

import {
  Button,
  Form,
  Input,
  InputNumber,
  Popconfirm,
  Table,
  message
} from 'antd';
const EditableContext = React.createContext();

class EditableCell extends React.Component {
  getInput = () => {
    if (this.props.inputType === 'number') {
      return <InputNumber />;
    }
    return <Input />;
  };

  renderCell = ({ getFieldDecorator }) => {
    const {
      editing,
      dataIndex,
      title,
      inputType,
      record,
      index,
      children,
      ...restProps
    } = this.props;

    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item style={{ margin: 0 }}>
            {getFieldDecorator(dataIndex, {
              rules: [
                {
                  required: true,
                  message: `Please Input ${title}!`
                }
              ],
              initialValue: record[dataIndex]
            })(this.getInput())}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  render() {
    return (
      <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>
    );
  }
}

const EditableTable = props => {
  const [editingKey, setEditingKey] = useState('');

  const isEditing = record => record._id === editingKey;

  const edit = key => {
    setEditingKey(key);
  };

  const cancel = () => {
    setEditingKey('');
  };

  const save = (form, id) => {
    form.validateFields((error, row) => {
      if (error) {
        return;
      }

      const newData = props.dataSource;
      const index = newData.findIndex(item => id === item._id);

      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row
        });

        setEditingKey('');
        props.onSave(newData[index]);
      } else {
        message.error('Sould not find advert to edit');
      }
    });
  };

  const components = {
    body: {
      cell: EditableCell
    }
  };

  const operationalColumn = [
    {
      title: '',
      dataIndex: 'operation',
      width: 140,
      render: (text, record) => {
        const editable = isEditing(record);
        return editable ? (
          <div style={{ display: 'flex' }}>
            <Popconfirm
              title="Sure to cancel?"
              onConfirm={() => cancel(record.key)}
            >
              <Button
                type="danager"
                shape="circle"
                icon="close"
                size="small"
                style={{ margin: '10px' }}
              />
            </Popconfirm>
            <EditableContext.Consumer>
              {form => (
                <Button
                  type="primary"
                  onClick={() => save(form, record._id)}
                  shape="circle"
                  icon="save"
                  ghost
                  size="small"
                  style={{ margin: '10px' }}
                />
              )}
            </EditableContext.Consumer>
          </div>
        ) : (
          <div style={{ display: 'flex', width: '100%' }}>
            <Popconfirm
              title="Are you sure?"
              onConfirm={() => props.onDelete(record._id)}
            >
              <Button
                type="danger"
                shape="circle"
                ghost
                size="small"
                icon="delete"
                style={{ margin: '10px' }}
              />
            </Popconfirm>

            <Button
              disabled={editingKey !== ''}
              onClick={() => edit(record._id)}
              type="primary"
              shape="circle"
              icon="edit"
              ghost
              size="small"
              style={{ margin: '10px' }}
            />
          </div>
        );
      }
    }
  ];

  const editableColumns = props.columns.map(col => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: record => ({
        record,
        inputType: 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record)
      })
    };
  });

  if (props.hasError) {
    return (
      <h2>
        Sorry something went wrong. If you know how, please check the console to
        see what the problem is
      </h2>
    );
  }

  const newCols = editableColumns.concat(operationalColumn);

  return (
    <EditableContext.Provider value={props.form}>
      <Table
        {...props}
        loading={props.isLoading}
        dataSource={props.dataSource}
        columns={newCols}
        components={components}
      />
    </EditableContext.Provider>
  );
};

export default Form.create()(EditableTable);
