import React from 'react';

import { Link } from 'react-router-dom';
import { Table } from 'antd';
import AdvertsData from 'Data/AdvertsData';
import Card from 'Components/Card';
import Title from 'Components/Title';

import Media from 'react-media';
import moment from 'moment';

const DashboardLatestAdverts = () => {
  const columns = [
    {
      title: 'Headline',
      dataIndex: 'headline',
      key: 'headline',
      width: 100,
      render: (advert, r) => {
        return (
          <Link to={`adverts/${r._id}`}>
            <b>{advert}</b>
          </Link>
        );
      }
    },

    {
      title: 'Created',
      dataIndex: 'createdAt',
      width: 100,
      hideOnSmall: true,
      key: 'createdAt',
      render: date => {
        const d = moment(date).calendar(null, {
          sameDay: '[Today]',
          nextDay: '[Tomorrow]',
          nextWeek: 'dddd',
          lastDay: '[Yesterday]',
          lastWeek: 'dddd',
          sameElse: 'DD/MM/YYYY'
        });

        return `${d}`;
      }
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price',
      hideOnSmall: true,
      width: 100,
      render: i => {
        if (i) {
          const number = parseInt(i, 10);
          return number.toLocaleString('en', {
            style: 'currency',
            currency: 'GBP'
          });
        }
        return 'POA';
      }
    }

    // {
    //   title: "User",
    //   dataIndex: "userId",
    //   key: "userId",
    //   width: 100,

    //   render: ({ _id, profile }) => {
    //     const { firstName, lastName } = profile || "";
    //     return (
    //       <b>
    //         <Link to={`/user/${_id}`}>
    //           {firstName} {lastName}
    //         </Link>
    //       </b>
    //     );
    //   }
    // }
  ];
  const getResponsiveColumns = smallScreen =>
    columns.filter(({ hideOnSmall = false }) => !(smallScreen && hideOnSmall));
  return (
    <Card>
      <Title title='Latest 10 Adverts' />
      <AdvertsData sort={{ createdAt: -1 }} limit={10}>
        {({ isLoading, data }) => {
          return (
            <Media query='(max-width: 780px)'>
              {smallScreen => {
                return (
                  <Table
                    showHeader={false}
                    loading={isLoading}
                    rowKey={'_id'}
                    dataSource={data}
                    columns={getResponsiveColumns(smallScreen)}
                    pagination={false}
                  />
                );
              }}
            </Media>
          );
        }}
      </AdvertsData>
    </Card>
  );
};

export default DashboardLatestAdverts;
