var AWS = require('aws-sdk');

AWS.config.update({
  region: 'eu-west-2',
  credentials: {
    accessKeyId: process.env.REACT_APP_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_SECRET_KEY,
  },
});

var docClient = new AWS.DynamoDB.DocumentClient();

module.exports = (TableName, id) =>
  new Promise((resolve) => {
    var params = {
      TableName,
      Key: {
        id: id,
      },
    };

    console.log('Attempting a conditional delete...');
    docClient.delete(params, function (err, data) {
      if (err) {
        console.error(
          'Unable to delete item. Error JSON:',
          JSON.stringify(err, null, 2)
        );
      } else {
        console.log('DeleteItem succeeded:', JSON.stringify(data, null, 2));
        resolve();
      }
    });
  });
