import React from "react";

import styled from "styled-components";

const Container = styled.div`
  min-width: 300px;
  background: white;
  border-radius: 5px;
  text-align: ${props => (props.align ? props.align : "left")};
  padding: 2rem;
  margin: 2rem;
  box-shadow: ${props =>
    !props.noShadow && "0 37.125px 70px -12.125px rgba(0, 0, 0, 0.2)"};
  transition: 0.5s;
  :hover {
    box-shadow: ${props =>
      !props.noShadow && "0 37.125px 70px -12.125px rgba(0, 0, 0, 0.3)"};
  }
`;

const Card = props => {
  return <Container {...props}>{props.children}</Container>;
};

export default Card;
