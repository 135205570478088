import { withRouter } from "react-router-dom";
import React, { Component } from "react";

import { Button } from "antd";
import Card from "./Card";

class SignOut extends Component {
  render() {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Card>
          <h2>You have been logged out</h2>
          <Button
            size="large"
            block
            onClick={() => this.props.history.push("/dashboard")}
          >
            Login
          </Button>
        </Card>
      </div>
    );
  }
}

export default withRouter(SignOut);
