// @flow

import React from 'react';

import {
  Alert,
  Avatar,
  Badge,
  Button,
  Checkbox,
  Col,
  Collapse,
  ConfigProvider,
  Divider,
  Form,
  Icon,
  Input,
  InputNumber,
  List,
  Modal,
  Radio,
  Row,
  Select,
  Spin,
  Table,
  Tag,
  Tooltip,
  message,
} from 'antd';

import { BigPlayButton, Player } from 'video-react';

import 'react-google-places-autocomplete/dist/assets/index.css';
import { isEqual, isObject, transform } from 'lodash';
import Geocode from 'react-geocode';
import GoogleMapReact from 'google-map-react';
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
} from 'react-google-places-autocomplete';
import _ from 'lodash';
import axios from 'axios';
import styled from 'styled-components';

import ColoursData from 'Data/ColoursData';
import DisciplineData from 'Data/DisciplineData';
import GenderData from 'Data/GenderData';
import HorseTypeData from 'Data/HorseTypeData';
import SaleTypeData from 'Data/SaleTypeData';

import '../../video.css';
import ImageUploader from 'Components/ImageUploader';
import api from 'api';
const { Column } = Table;
const { Panel } = Collapse;

const FormItem = Form.Item;

const { TextArea, Search } = Input;
const { Option } = Select;
const { REACT_APP_GEOCODE_API } = process.env;

// set Google Maps Geocoding API for purposes of quota management. Its optional but recommended.
Geocode.setApiKey(REACT_APP_GEOCODE_API);

const Title = styled.h2`
  font-weight: 800;
  display: inline-block;
`;

const customEmptyState = () => (
  <div style={{ textAlign: 'center', marginTop: '2rem' }}>
    <Icon type='video-camera' style={{ fontSize: 20 }} />
    <p>No YouTube Videos</p>
  </div>
);

class AdvertFormScene extends React.Component {
  constructor(props) {
    super(props);
    this.lastFetchId = 0;
    this.fetchUser = _.debounce(this.fetchUser, 800);
  }

  _isMounted = false;

  state = {
    previewVisible: false,
    showVideoPlayer: false,
    previewImage: '',
    fileList: [],
    videoList: [],
    advert: {},
    error: false,
    users: [],
    fetchingUsers: false,
    colours: [],
    horseType: '5c98fcbc48893164f136e649',
    location: '',
    loading: true,
    saleType: '',
    isPOA: false,
    isFree: false,
    conversationCount: 0,
    transactions: [],
    copiedVisible: false,
  };

  componentDidMount() {
    this._isMounted = true;

    const { params } = this.props.match || {};

    if (params && params.id && this._isMounted) {
      this.getAdvertDetails(params.id);
      this.getConversations(params.id);
      this.getTransactions(params.id);
    } else {
      this.setState({ loading: false });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  difference(object, base) {
    return transform(object, (result, value, key) => {
      if (!isEqual(value, base[key])) {
        result[key] =
          isObject(value) && isObject(base[key])
            ? this.difference(value, base[key])
            : value;
      }
    });
  }

  fetchUser = async (value) => {
    this.lastFetchId += 1;

    this.setState({ users: [], fetchingUsers: true });

    const query = { $text: { $search: value }, deletedAt: { $exists: 0 } };

    const { data } = await api.get('/User', {
      force: true,
      params: {
        query: query,
        sort: { 'profile.firstName': 1 },
      },
    });

    this.setState({ users: data, fetchingUsers: false });
  };

  getAdvertDetails = async (id) => {
    try {
      const { data: advert } = await api.get(
        `/Advert/${id}?populate=[{"path": "userId"},{"path": "colour"},{"path": "discipline"}, {"path": "gender"}, {"path": "type"}, {"path": "saleType"}]`
      );
      if (this._isMounted) {
        if (advert) {
          this.setState({
            advert,
            users: [advert.userId],
            loading: false,
            horseType: advert.type && advert.type._id,
            disciplines: advert.discipline && advert.discipline,
            saleType: advert.saleType && advert.saleType.label,
          });

          if (advert.media && advert.media.length > 0) {
            this.setState({
              loading: false,
              videoList: advert.media
                .filter((file) => file.type === 'video')
                .map((file) => ({
                  id: file._id,
                  name: file.fileName,
                  url: `${process.env.REACT_APP_AWS_S3_URL}/adverts/${file.fileName}`,
                  type: file.type,
                  isNew: false,
                })),
              fileList: advert.media
                .filter((file) => file.type !== 'video')
                .map((file) => ({
                  id: file._id,
                  name: file.fileName,
                  url: `${process.env.REACT_APP_AWS_S3_URL}/adverts/${file.fileName}`,
                  type: file.type,
                  isNew: false,
                })),
            });
          }
        }
      }
    } catch (e) {
      console.log('e', e);
      if (this._isMounted) {
        this.setState({ error: true });
      }
    }
  };

  getConversations = async (id) => {
    const query = { advertId: id };

    try {
      const { data } = await api.get(`/Conversation/count`, {
        params: {
          query,
        },
      });

      this.setState({ conversationCount: data.count || 0 });

      return data.count || 0;
    } catch (e) {
      message.error('Could not get conversations');
    }
  };

  getTransactions = async (id) => {
    const query = { advertId: id };

    try {
      const { data } = await api.get(`/Transaction`, {
        params: {
          query,
        },
      });

      this.setState({ transactions: data });

      return data.count || 0;
    } catch (e) {
      message.error('Could not get transactions');
    }
  };

  handleUpdate = (e) => {
    e && e.preventDefault();
    const {
      fileList,

      advert: { _id, userId: user },
    } = this.state;

    this.props.form.validateFieldsAndScroll(async (err, values) => {
      // const media = [];
      if (err) {
        console.error(err);
        return message.warning('Please check the form');
      }

      if (!_id) {
        return message.error('Could not find ID of the advert', 20);
      }

      // If the owner has changed, check if there are any conversations on this advert
      const isSameOwner = values.userId === user._id;
      if (!isSameOwner) {
        const conversationCount = await this.getConversations(_id);
        if (conversationCount > 0 && !isSameOwner) {
          return message.error(
            'Cannot change owner of this advert, there are existing conversations'
          );
        }
      }

      let media = [];

      if (fileList && fileList.length > 0) {
        fileList.forEach((file) => {
          media.push({ type: file.type, fileName: file.name });
        });
      }

      const data = Object.assign(values, {
        _id,
        media,
        syncedAt: new Date(),
        youTubeVideos: this.state.advert.youTubeVideos,
        exactCoords: this.state.advert.exactCoords,
        coords: this.state.advert.coords,
        formattedLocation: this.state.advert.formattedLocation,
        location: this.state.advert.location,
      });

      const locationError = (errorMessage) => {
        this.props.form.setFields({
          location: {
            value: this.state.advert.location,
            errors: [new Error(errorMessage)],
          },
        });
        return message.error(errorMessage, 100);
      };

      if (!data.coords) {
        return locationError('No coords found');
      }

      if (!data.exactCoords) {
        return locationError('No exact coords found');
      }

      if (!data.location) {
        return locationError('No location found');
      }
      if (!data.formattedLocation) {
        return locationError('No formatted location found');
      }

      api
        .patch(`/Advert/${_id}`, { ...data })
        .then((r) => {
          message.success('Updated the advert');
        })
        .catch((e) => {
          console.error(e);
          message.error('Something went wrong updating the advert');
        });
    });
  };

  handleSubmit = (e) => {
    e && e.preventDefault();
    if (!this.state.postcodeLoading) {
      this.props.form.validateFieldsAndScroll((err, values) => {
        if (!err && !this.state.postcodeError) {
          const { fileList } = this.state;

          let media = [];

          if (fileList && fileList.length > 0) {
            fileList.forEach((file) => {
              media.push({ type: file.type, fileName: file.name });
            });
          }

          const data = Object.assign(values, {
            exactCoords: this.state.advert.exactCoords,
            coords: this.state.advert.coords,
            formattedLocation: this.state.advert.formattedLocation,
            location: this.state.advert.location,
            media: media,
            syncedAt: new Date(),
            youTubeVideos: this.state.advert.youTubeVideos,
          });

          const locationError = (errorMessage) => {
            this.props.form.setFields({
              location: {
                value: this.state.advert.location,
                errors: [new Error(errorMessage)],
              },
            });
            return message.error(errorMessage, 100);
          };

          if (!data.coords) {
            return locationError('No coords found');
          }

          if (!data.exactCoords) {
            return locationError('No exactCoords found');
          }

          if (!data.location) {
            return locationError('No location found');
          }
          if (!data.formattedLocation) {
            return locationError('No formatted Location found');
          }

          api
            .post('Advert', data)
            .then((res) => {
              message.success('Advert created!');
            })
            .catch((error) => {
              console.error(error.error);
              message.error(error.error);
            });
        }
      });
    }
  };

  getCoords = ({ place_id }) => {
    if (!place_id) {
      return message.error('No place ID found');
    }

    geocodeByPlaceId(place_id)
      .then((response) => {
        const { formatted_address, geometry } = response[0];
        const locationData = response[0];

        const lat = geometry.location.lat();
        const lng = geometry.location.lng();
        this.coords = { lat, lng };
        this.location = formatted_address;

        let locationDisplay = 'Unkown';

        let target = _.find(locationData.address_components, (o) =>
          o.types.includes('postal_town')
        );
        const country = _.find(locationData.address_components, (o) =>
          o.types.includes('country')
        );
        if (!target) {
          target = _.find(locationData.address_components, (o) =>
            o.types.includes('administrative_area_level_2')
          );
        }
        if (!target) {
          target = _.find(locationData.address_components, (o) =>
            o.types.includes('administrative_area_level_1')
          );
        }
        if (!target) {
          target = _.find(locationData.address_components, (o) =>
            o.types.includes('country')
          );
        }

        if (target) {
          let queryName = target.long_name;
          if (
            target.long_name.substr(-country.short_name.length + 2) !==
            `, ${country.short_name}`
          ) {
            queryName = `${target.long_name}, ${country.short_name}`;
          }
          locationDisplay = target.long_name;
          Geocode.fromAddress(queryName)
            .then((json) => {
              const {
                geometry: { location: cityLocation },
              } = json.results[0];

              const advertUpdated = Object.assign(this.state.advert, {
                exactCoords: { lat, lng },
                coords: cityLocation,
                location: formatted_address,
                formattedLocation: locationDisplay,
              });

              this.setState({ advert: advertUpdated });
            })
            .catch((e) => {
              console.error(e);
              message.error('Could not geocode');
            });
        }
      })
      .catch((e) => {
        console.log(e);
        message.error('Could not geocode by placeId');
      });
  };

  onBlur = (key, value) => {
    if (key === 'postcode') {
      return this.getCoords(value, true);
    }
    if (key === 'location') {
      return this.getCoords(value, false);
    }
  };

  removeAdvert = () => {
    const { advert } = this.state;
    const { _id } = advert;

    if (!_id) {
      return message.error('Could not find the advert id to remove it');
    }

    api
      .patch(`Advert/${_id}`, { deletedAt: new Date(), syncedAt: new Date() })
      .then(() => {
        message.info('Removed advert');
        this.getAdvertDetails(_id);
      })
      .catch((e) => message.error('Sorry, could not remove this advert'));
  };

  restoreAdvert = () => {
    const { advert } = this.state;
    const { _id } = advert;

    if (!_id) {
      return message.error('Could not find the advert id to restore it');
    }

    api
      .patch(`Advert/${_id}`, { deletedAt: null, syncedAt: new Date() })
      .then(() => {
        message.success('Restored advert');
        this.getAdvertDetails(_id);
      })
      .catch((e) => {
        message.error('Could not restore the advert');
      });
  };

  handlePreview = (file) => {
    this.setState({
      previewImage: file.url || file.thumbUrl,
      previewVisible: true,
    });
  };

  handleChange = ({ fileList }) => this.setState({ fileList });

  handleCancel = () => this.setState({ previewVisible: false });

  markAsSold = async (isSold = true) => {
    const { advert } = this.state;
    const { _id, soldAt } = advert;
    try {
      await api.patch(`/Advert/${_id}`, {
        soldAt: !soldAt ? new Date() : null,
        syncedAt: new Date(),
      });
      message.success(soldAt ? 'Marked as not sold' : 'Marked as sold');
      this.getAdvertDetails(_id);
    } catch (e) {
      message.error(e.message);
    }
  };

  handlePOACheckbox = (e) => {
    this.setState({ POA: e.target.checked });
  };

  handleFreeCheckbox = (e) => {
    this.setState({ isFree: e.target.checked });
  };

  handleDisciplineChange = (tag, checked) => {
    const { disciplines } = this.state;
    const nextSelectedTags = checked
      ? [...disciplines, tag]
      : disciplines.filter((t) => t !== tag);

    this.setState({ disciplines: nextSelectedTags });
  };

  saveImageAfterUpload = (images) => {
    const newImages = images.map((image) => ({
      id: image.id,
      name: image.uploadURL.split('adverts/')[1],
      url: image.uploadURL,
      type: image.type.split('/')[0],
      isNew: true,
    }));

    const newList = [...this.state.fileList, ...newImages];
    this.setState({ fileList: newList });

    const { advert } = this.state || {};
    const { _id } = advert || null;

    // There will be no ID when creating an advert
    if (_id) {
      let media = [];

      if (newList && newList.length > 0) {
        newList.forEach((file) => {
          media.push({ type: file.type, fileName: file.name });
        });

        api
          .patch(`/Advert/${_id}`, { media, syncedAt: new Date() })
          .then((r) => {
            message.success('Added new media to advert');
          });
      }
    }
  };

  showVideoPlayer = () => {
    this.setState((prevState) => {
      if (prevState.showVideoPlayer === true) {
        this.player.pause();
      }
      return { showVideoPlayer: !prevState.showVideoPlayer };
    });
  };
  // Copies a string to the clipboard. Must be called from within an
  // event handler such as click. May return false if it failed, but
  // this is not always possible. Browser support for Chrome 43+,
  // Firefox 42+, Safari 10+, Edge and Internet Explorer 10+.
  // Internet Explorer: The clipboard feature may be disabled by
  // an administrator. By default a prompt is shown the first
  // time the clipboard is used (per session).
  copyToClipboard = (text) => {
    if (window.clipboardData && window.clipboardData.setData) {
      // Internet Explorer-specific code path to prevent textarea being shown while dialog is visible.
      return window.clipboardData.setData('Text', text);
    } else if (
      document.queryCommandSupported &&
      document.queryCommandSupported('copy')
    ) {
      var textarea = document.createElement('textarea');
      textarea.textContent = text;
      textarea.style.position = 'fixed'; // Prevent scrolling to bottom of page in Microsoft Edge.
      document.body.appendChild(textarea);
      textarea.select();
      try {
        return document.execCommand('copy'); // Security exception may be thrown by some browsers.
      } catch (ex) {
        console.warn('Copy to clipboard failed.', ex);
        return false;
      } finally {
        document.body.removeChild(textarea);
      }
    }
  };
  render() {
    const {
      form: { getFieldDecorator },
    } = this.props;

    const {
      error,
      fileList,
      advert,
      newLocation,
      horseType,
      loading,
      users,
      fetchingUsers,
      transactions,
    } = this.state;

    if (error) {
      return (
        <div>
          <h2>Error</h2>
        </div>
      );
    }

    if (loading) {
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            minHeight: '100vh',
          }}
        >
          <Spin />
        </div>
      );
    }
    const style = {
      formItem: { marginBottom: '1.7em', width: '100%' },
    };
    const formItemLayout = {
      labelCol: { span: 24 },
      wrapperCol: { span: 24 },
    };

    const {
      _id,
      headline,
      age,
      breed,
      colour,
      description,
      height,
      location,
      userId,
      price,
      gender,
      type,
      saleType,
      discipline,
      deletedAt,
      status,
      soldAt,
      userSuspended,
      isPOA,
      isFree,
      exactCoords,
      publishedAt,
      formattedLocation,
      youTubeVideos = [],
    } = advert;

    const handleYouTubeLink = async (youTubeURL) => {
      // eslint-disable-next-line no-useless-escape
      const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
      const match = youTubeURL.match(regExp);
      if (match && match[2].length === 11) {
        try {
          const { data } = await axios.get(
            `https://www.googleapis.com/youtube/v3/videos?id=${match[2]}&key=AIzaSyAng2fF0LVWyva2yfSvlTqEKIPit7lTg18&part=snippet`
          );

          const item = data.items[0];
          const {
            id,
            snippet: { title, description: youTubeDescription },
          } = item;

          const newYouTubeVideos = [
            ...youTubeVideos,
            { id, title, description: youTubeDescription },
          ];
          const newAdvert = { ...advert, youTubeVideos: newYouTubeVideos };
          this.setState({ advert: newAdvert });
        } catch (e) {
          message.error('Something went wrong adding the video');
        }
      } else {
        message.error(
          'Could not match the YouTube link, check if the url is correct'
        );
      }
    };

    const handleRemoveYouTubeVideo = (id) => {
      const videos = youTubeVideos.filter((v) => v.id !== id);

      const updatedAd = { ...advert, youTubeVideos: videos };

      this.setState({ advert: updatedAd });
    };

    const colLayout = {
      sm: {
        offset: 0,
        span: 24,
      },
      md: {
        offset: 0,
        span: 24,
      },
      xl: {
        offset: 2,
        span: 16,
      },
      xxl: {
        offset: 4,
        span: 8,
      },
    };

    const offsetLayout = {
      sm: {
        offset: 0,
      },
      md: {
        offset: 0,
      },
      xl: {
        offset: 2,
      },
      xxl: {
        offset: 4,
      },
    };

    return (
      <Form
        hideRequiredMark
        layout='inline'
        onSubmit={_id ? this.handleUpdate : this.handleSubmit}
      >
        {deletedAt && (
          <Alert
            showIcon
            closeText='Restore'
            onClose={this.restoreAdvert}
            style={{ marginBottom: '2rem' }}
            message='This advert has been deleted'
            type='warning'
          />
        )}

        {userSuspended && (
          <Alert
            showIcon
            style={{ marginBottom: '2rem' }}
            message='The owner of this advert has been suspended'
            type='warning'
          />
        )}

        {status === 2 && (
          <Alert
            style={{ marginBottom: '2rem' }}
            message='This advert is currently a draft'
            type='info'
          />
        )}

        {soldAt && (
          <Alert
            style={{ marginBottom: '2rem' }}
            message='This advert is Sold'
            type='info'
          />
        )}

        <Row gutter={16} style={{ marginBottom: '2rem' }}>
          <Col span={24} style={{ textAlign: 'left' }}>
            <Title>{headline || 'Advert'}</Title>
            {_id && (
              <Tooltip title='Copied link!' visible={this.state.copiedVisible}>
                <Icon
                  onClick={() => {
                    this.copyToClipboard(
                      'https://app.whickr.com/adverts/' + _id
                    );
                    this.setState({ copiedVisible: true }, () => {
                      setTimeout(() => {
                        this.setState({ copiedVisible: false });
                      }, 3000);
                    });
                  }}
                  style={{ marginLeft: 10 }}
                  type='copy'
                />
              </Tooltip>
            )}
            <br />

            {!publishedAt && <Tag color='blue'>Free</Tag>}
            {publishedAt && transactions && transactions.length > 0 && (
              <Tag color='green'>{`Paid: £${transactions[0].price}`}</Tag>
            )}
            {publishedAt && transactions && transactions.length === 0 && (
              <Tag color='blue'>Billed Offline</Tag>
            )}
          </Col>
        </Row>
        <Row>
          <Col {...colLayout} {...offsetLayout}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignContent: 'center',
                alignItems: 'center',
                marginBottom: 30,
              }}
            >
              {fileList.map((image, i) => {
                return (
                  <div key={image.id || i} style={{ marginRight: '2rem' }}>
                    <Badge
                      count={
                        <Icon
                          onClick={() =>
                            this.setState((prevState) => {
                              const newImages = _.remove(
                                prevState.fileList,
                                function (n) {
                                  return n.id !== image.id;
                                }
                              );

                              return { fileList: newImages };
                            })
                          }
                          type='close'
                          style={{ color: '#f5222d' }}
                        />
                      }
                    >
                      <Avatar
                        shape='square'
                        key={image.id}
                        size={64}
                        src={image.url}
                      />
                    </Badge>
                  </div>
                );
              })}

              {this.state.videoList.map((image) => {
                return (
                  <div style={{ marginRight: '2rem' }}>
                    <Modal
                      title={image.fileName}
                      visible={this.state.showVideoPlayer}
                      onCancel={this.showVideoPlayer}
                      footer={false}
                      header={false}
                    >
                      <Player
                        ref={(player) => {
                          this.player = player;
                        }}
                        src={image.url}
                      >
                        <BigPlayButton position='center' />
                      </Player>
                    </Modal>
                    <Avatar
                      onClick={this.showVideoPlayer}
                      shape='square'
                      key={image.id}
                      size={64}
                      src={image.thumbnailUrl}
                      icon='play-circle'
                    />
                  </div>
                );
              })}

              <ImageUploader onSuccess={this.saveImageAfterUpload} />
            </div>
          </Col>
        </Row>

        <Row style={{ marginBottom: '2rem', marginTop: '2rem' }}>
          <Collapse>
            <Panel header='Image Links' key='1'>
              <Table
                showHeader={false}
                pagination={false}
                dataSource={fileList}
              >
                <Column
                  title='URL'
                  dataIndex='url'
                  key='url'
                  render={(link) => {
                    return <a href={link}>{link}</a>;
                  }}
                />
              </Table>
            </Panel>
            {this.state.videoList && this.state.videoList.length > 0 && (
              <Panel header='Videos Links' key='2'>
                <Table
                  showHeader={false}
                  pagination={false}
                  dataSource={this.state.videoList}
                >
                  <Column
                    title='URL'
                    dataIndex='url'
                    key='url'
                    render={(link) => {
                      return <a href={link}>{link}</a>;
                    }}
                  />
                </Table>
              </Panel>
            )}
          </Collapse>
        </Row>

        <Row gutter={16}>
          <Col {...colLayout} {...offsetLayout}>
            <ConfigProvider renderEmpty={customEmptyState}>
              <List
                header={<h4 style={{ fontWeight: 800 }}>YouTube Videos</h4>}
                style={{ marginBottom: '2rem' }}
                itemLayout='horizontal'
                dataSource={youTubeVideos}
                renderItem={(item) => {
                  const url = `https://youtu.be/${item.id}`;
                  return (
                    <List.Item>
                      <List.Item.Meta
                        title={<a href={url}>{item.title}</a>}
                        description={<a href={url}>{url}</a>}
                      />
                      <Button
                        ghost
                        type='danger'
                        icon='delete'
                        onClick={() => handleRemoveYouTubeVideo(item.id)}
                      />
                    </List.Item>
                  );
                }}
              />
            </ConfigProvider>
            <Search
              size='large'
              value={this.state.youtubeLink}
              onChange={(e) => this.setState({ youtubeLink: e.target.value })}
              enterButton
              onSearch={(v) => {
                handleYouTubeLink(v);
                this.setState({ youtubeLink: '' });
              }}
              placeholder='https://www.youtube.com/watch?v=uCbFMZYQbxE'
              style={{ marginBottom: '2rem' }}
            />
          </Col>
          <Col {...colLayout} {...offsetLayout}>
            <FormItem style={style.formItem} {...formItemLayout} label='Status'>
              {getFieldDecorator('status', {
                initialValue: status || 1,
                rules: [
                  {
                    required: true,
                    message: 'Make sure to provide a status',
                  },
                ],
              })(
                <Radio.Group buttonStyle='solid' size='large'>
                  <Radio.Button value={2} key={2}>
                    Draft
                  </Radio.Button>
                  <Radio.Button value={1} key={1}>
                    Live
                  </Radio.Button>
                </Radio.Group>
              )}
            </FormItem>
          </Col>
          <Col {...colLayout} {...offsetLayout}>
            <FormItem
              style={style.formItem}
              {...formItemLayout}
              label='Headline'
            >
              {getFieldDecorator('headline', {
                initialValue: headline,
                rules: [
                  {
                    required: true,
                    message: 'Please provide an headline',
                    whitespace: true,
                  },
                ],
              })(
                <Input
                  placeholder='A great horse that will never let you down'
                  size='large'
                />
              )}
            </FormItem>
          </Col>

          <Col {...colLayout} {...offsetLayout}>
            <FormItem style={style.formItem} {...formItemLayout} label='Price'>
              {getFieldDecorator('price', {
                initialValue: price,
                rules: [
                  {
                    required: false,
                    message: 'Please provide a price',
                  },
                ],
              })(
                <InputNumber
                  style={{ width: '100%' }}
                  placeholder='10000'
                  prefix={
                    <Icon type='pound' style={{ color: 'rgba(0,0,0,.25)' }} />
                  }
                  size='large'
                />
              )}
            </FormItem>
          </Col>

          <Col {...colLayout} {...offsetLayout}>
            <SaleTypeData>
              {({ isLoading, isError, data }) => {
                return (
                  <FormItem
                    style={style.formItem}
                    {...formItemLayout}
                    label='Sale Type'
                  >
                    {getFieldDecorator('saleType', {
                      initialValue: saleType && saleType._id,
                      rules: [
                        {
                          required: true,
                          message: 'Please provide a Sale Type',
                        },
                      ],
                    })(
                      <Radio.Group buttonStyle='solid' size='large'>
                        {data &&
                          data.length > 0 &&
                          data.map((v) => {
                            return (
                              <Radio.Button
                                value={v._id}
                                key={v._id}
                                onChange={(e) => {
                                  this.setState({
                                    saleType: v.label,
                                  });
                                }}
                              >
                                {v.label}
                              </Radio.Button>
                            );
                          })}
                        {this.state.saleType === 'Sale' && (
                          <Form.Item
                            style={{ marginLeft: 20 }}
                            label='Is this POA?'
                          >
                            {getFieldDecorator('isPOA', {
                              initialValue: isPOA || false,
                            })(
                              <Checkbox defaultChecked={isPOA ? isPOA : false}>
                                POA
                              </Checkbox>
                            )}
                          </Form.Item>
                        )}
                        {this.state.saleType === 'Loan' && (
                          <Form.Item
                            style={{ marginLeft: 20 }}
                            label='Is this free to loan?'
                          >
                            {getFieldDecorator('isFree', {
                              initialValue: isFree || false,
                            })(
                              <Checkbox
                                defaultChecked={isFree ? isFree : false}
                              >
                                Free
                              </Checkbox>
                            )}
                          </Form.Item>
                        )}
                      </Radio.Group>
                    )}
                  </FormItem>
                );
              }}
            </SaleTypeData>
          </Col>
          <Col {...colLayout} {...offsetLayout}>
            <HorseTypeData>
              {({ isLoading, isError, data }) => {
                return (
                  <FormItem
                    style={style.formItem}
                    {...formItemLayout}
                    label='Type'
                  >
                    {getFieldDecorator('type', {
                      initialValue: type ? type._id : horseType,
                      rules: [
                        {
                          required: true,
                          message: 'Please provide a type',
                        },
                      ],
                    })(
                      <Radio.Group buttonStyle='solid' size='large'>
                        {data &&
                          data.length > 0 &&
                          data.map((v) => {
                            return (
                              <Radio.Button
                                onChange={(e) => {
                                  this.setState({
                                    horseType: e.target.value,
                                  });
                                }}
                                value={v._id}
                                key={v._id}
                              >
                                {v.label}
                              </Radio.Button>
                            );
                          })}
                      </Radio.Group>
                    )}
                  </FormItem>
                );
              }}
            </HorseTypeData>
          </Col>
          <Col {...colLayout} {...offsetLayout}>
            <FormItem
              style={style.formItem}
              {...formItemLayout}
              label='Description'
            >
              {getFieldDecorator('description', {
                initialValue: description || '',
                rules: [
                  {
                    required: true,
                    message: 'Please provide a Description',
                  },
                ],
              })(
                <TextArea
                  placeholder='An average height male that loves to be by your side.'
                  size='large'
                  style={{ height: '200px', fontSize: '16px' }}
                />
              )}
            </FormItem>
          </Col>

          {/* <Col {...colLayout} {...offsetLayout}>
            <FormItem
              style={style.formItem}
              {...formItemLayout}
              label='YouTube Videos'
            >
              {getFieldDecorator('youTubeVideo', {
                initialValue: description || '',
                rules: [
                  {
                    required: false,
                    message: 'Enter YouTube Links'
                  }
                ]
              })(
                <Search
                  placeholder='YouTube Link'
                  loading
                  enterButton='Add'
                  size='large'
                />
              )}
            </FormItem>
          </Col> */}
        </Row>
        <Divider />

        <Row gutter={16}>
          <Col style={{ textAlign: 'left' }}>
            <Title>Horse</Title>
          </Col>
          <Col {...colLayout} {...offsetLayout}>
            <FormItem style={style.formItem} {...formItemLayout} label='Height'>
              {getFieldDecorator('height', {
                initialValue: height,
                rules: [
                  {
                    required: true,
                    message: 'Please provide a height',
                  },
                ],
              })(
                <InputNumber
                  style={{ width: '100%' }}
                  min={0}
                  max={40}
                  step={0.1}
                  placeholder='5.1'
                  size='large'
                />
              )}
            </FormItem>
          </Col>
          <Col {...colLayout} {...offsetLayout}>
            <FormItem style={style.formItem} {...formItemLayout} label='Age'>
              {getFieldDecorator('age', {
                initialValue: age,
                rules: [
                  {
                    required: true,
                    message: 'Please provide a Age',
                  },
                ],
              })(
                <InputNumber
                  style={{ width: '100%' }}
                  placeholder='29'
                  size='large'
                />
              )}
            </FormItem>
          </Col>
          <Col {...colLayout} {...offsetLayout}>
            <GenderData>
              {({ isLoading, isError, data }) => {
                return (
                  <FormItem
                    style={style.formItem}
                    {...formItemLayout}
                    label='Gender'
                  >
                    {getFieldDecorator('gender', {
                      initialValue: gender && gender._id,
                      rules: [
                        {
                          required: true,
                          message: 'Please provide a gender',
                        },
                      ],
                    })(
                      <Radio.Group
                        style={{ width: '100%' }}
                        buttonStyle='solid'
                        size='large'
                      >
                        {data &&
                          data.length > 0 &&
                          data.map((v) => {
                            return (
                              <Radio.Button value={v._id} key={v._id}>
                                {v.label}
                              </Radio.Button>
                            );
                          })}
                      </Radio.Group>
                    )}
                  </FormItem>
                );
              }}
            </GenderData>
          </Col>

          <Col {...colLayout} {...offsetLayout}>
            <DisciplineData>
              {({ isLoading, isError, data }) => {
                const filteredDisciplines = data.filter(
                  (d) => d.type === horseType
                );

                return (
                  <Form.Item
                    style={style.formItem}
                    {...formItemLayout}
                    label='Disciplines'
                  >
                    {getFieldDecorator('discipline', {
                      initialValue: discipline && discipline.map((d) => d._id),
                      rules: [
                        {
                          type: 'array',
                          required: true,
                          message: 'Pick at least one discipline',
                        },
                      ],
                    })(
                      <Select
                        mode='multiple'
                        size='large'
                        placeholder='Select disciplines'
                      >
                        {filteredDisciplines &&
                          filteredDisciplines.length > 0 &&
                          filteredDisciplines.map((c) => {
                            return (
                              <Option value={c._id} key={c._id}>
                                {c.label}
                              </Option>
                            );
                          })}
                      </Select>
                    )}
                  </Form.Item>
                );
              }}
            </DisciplineData>
          </Col>

          <Col {...colLayout} {...offsetLayout}>
            <ColoursData>
              {({ isLoading, isError, data }) => {
                return (
                  <Form.Item
                    style={style.formItem}
                    {...formItemLayout}
                    label='Colour'
                  >
                    {getFieldDecorator('colour', {
                      initialValue:
                        colour && colour.length > 0 && colour[0]._id,
                      rules: [
                        {
                          required: true,
                          message: 'Please choose a colour',
                        },
                      ],
                    })(
                      <Select
                        size='large'
                        placeholder='Select the horse colors'
                      >
                        {data &&
                          data.length > 0 &&
                          data.map((c) => {
                            return (
                              <Option value={c._id} key={c._id}>
                                {c.label}
                              </Option>
                            );
                          })}
                      </Select>
                    )}
                  </Form.Item>
                );
              }}
            </ColoursData>
          </Col>
          <Col {...colLayout} {...offsetLayout}>
            <FormItem style={style.formItem} {...formItemLayout} label='Breed'>
              {getFieldDecorator('breed', {
                initialValue: breed,
                rules: [
                  {
                    required: true,
                    message: 'Please provide a breed',
                  },
                ],
              })(<Input placeholder='Jersey' size='large' />)}
            </FormItem>
          </Col>
        </Row>
        <Divider />
        <Row gutter={16}>
          <Col style={{ textAlign: 'left' }}>
            <Title>Seller</Title>
          </Col>
          <Col {...colLayout} {...offsetLayout}>
            <FormItem
              style={style.formItem}
              {...formItemLayout}
              label='Seller'
              help={
                this.state.conversationCount > 0 &&
                `Looks like there is ${this.state.conversationCount} conversation(s) about this advert, so the owner can no longer be changed.`
              }
            >
              {getFieldDecorator('userId', {
                initialValue: userId && userId._id,
                rules: [
                  {
                    required: true,
                    message: 'Please provide an Seller',
                    whitespace: true,
                  },
                ],
              })(
                <Select
                  disabled={this.state.conversationCount > 0}
                  showSearch
                  showArrow={false}
                  suffixIcon={(userId && userId.email) || ''}
                  size='large'
                  notFoundContent={fetchingUsers ? <Spin size='small' /> : null}
                  filterOption={false}
                  onSearch={this.fetchUser}
                >
                  {users &&
                    users.length > 0 &&
                    users.map((user) => {
                      if (user && user.profile) {
                        return (
                          <Option key={user._id} value={user._id}>
                            {`${user.profile.firstName} ${user.profile.lastName} (${user.email})`}
                          </Option>
                        );
                      } else {
                        return (
                          <Option key={user._id} value={user._id}>
                            missing profile
                          </Option>
                        );
                      }
                    })}
                </Select>
              )}
            </FormItem>
          </Col>

          <Col {...colLayout} {...offsetLayout}>
            <FormItem
              style={style.formItem}
              {...formItemLayout}
              label='Location'
              hasFeedback={this.state.postcodeLoading}
              validateStatus={this.state.postcodeError ? 'error' : 'validating'}
              help={this.state.postcodeError}
            >
              {getFieldDecorator('location', {
                initialValue: location,
                setFieldsValue: location || '',
                rules: [
                  {
                    required: true,
                    message: 'Please provide a location',
                  },
                ],
              })(
                <>
                  <GooglePlacesAutocomplete
                    placeholder='Barry, Cardiff'
                    required
                    initialValue={location}
                    renderInput={(props) => (
                      <Input
                        prefix={
                          <Icon
                            type='environment'
                            style={{ color: 'rgba(0,0,0,.25)' }}
                          />
                        }
                        size='large'
                        {...props}
                      />
                    )}
                    autocompletionRequest={{
                      componentRestrictions: {
                        country: ['uk', 'ie', 'gg', 'ge', 'im'],
                      },
                    }}
                    onSelect={this.getCoords}
                  />
                </>
              )}
            </FormItem>
          </Col>

          <Col {...colLayout} {...offsetLayout}>
            {/* <FormItem
              style={style.formItem}
              {...formItemLayout}
              label="Postcode"
              hasFeedback={this.state.postcodeLoading}
              validateStatus={this.state.postcodeError ? "error" : "validating"}
              help={this.state.postcodeError}
            >
              {getFieldDecorator("postcode", {
                initialValue: postcode,
                rules: [
                  {
                    required: true,
                    message: 'Please provide a valid postcode',
                    pattern: new RegExp(postcodePattern, 'i')
                  }
                ]
              })(
                <Input.Search
                  style={{ width: "100%" }}
                  size="large"
                  placeholder="CF82 2FT"
                  prefix={
                    <Icon
                      type="environment"
                      style={{ color: "rgba(0,0,0,.25)" }}
                    />
                  }
                  enterButton="Look Up"
                  onSearch={value => this.onBlur("postcode", value)}
                />
              )}
            </FormItem> */}
          </Col>
          {exactCoords && (
            <Col
              lg={{ offset: 0, span: 24 }}
              xl={{ offset: 2, span: 22 }}
              xxl={{ offset: 4, span: 20 }}
            >
              <div style={{ height: '400px', width: '100%' }}>
                {<h4>{newLocation || formattedLocation}</h4>}
                <GoogleMapReact
                  bootstrapURLKeys={{
                    key: 'AIzaSyD7uhsy_SJvKzHiTzTcGyizEb7kaclMSTg',
                  }}
                  defaultCenter={exactCoords}
                  defaultZoom={11}
                ></GoogleMapReact>
              </div>
            </Col>
          )}
        </Row>
        <Row>
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'flex-end',
              marginTop: '4rem',
            }}
          >
            {_id ? (
              <React.Fragment>
                <Button
                  icon={deletedAt ? 'undo' : 'delete'}
                  size='large'
                  type={deletedAt ? 'ghost' : 'danger'}
                  ghost={!deletedAt}
                  style={{
                    marginRight: '2rem',
                    marginTop: '2rem',
                    marginBottom: '2rem',
                  }}
                  onClick={deletedAt ? this.restoreAdvert : this.removeAdvert}
                >
                  {deletedAt ? 'Restore Advert' : 'Remove Advert'}
                </Button>
                {!deletedAt && _id && (
                  <Button
                    size='large'
                    icon={soldAt ? 'close' : 'check'}
                    onClick={this.markAsSold}
                    style={{
                      marginRight: '2rem',
                      marginTop: '2rem',
                      marginBottom: '2rem',
                    }}
                  >
                    {soldAt ? 'Mark as Not Sold' : 'Mark as Sold'}
                  </Button>
                )}
                <Button
                  icon='form'
                  size='large'
                  type='primary'
                  htmlType='submit'
                  className='login-form-button'
                  style={{ marginTop: '2rem', marginBottom: '2rem' }}
                >
                  Update Advert
                </Button>
              </React.Fragment>
            ) : (
              <Button
                size='large'
                type='primary'
                htmlType='submit'
                style={{
                  marginTop: '2rem',
                  marginBottom: '2rem',
                }}
              >
                Create a new Advert
              </Button>
            )}
          </div>
        </Row>
      </Form>
    );
  }
}

export default Form.create()(AdvertFormScene);
