import { useEffect, useState } from 'react';

import { message } from 'antd';
import api from 'api';

const AdvertsData = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [data, setData] = useState([]);
  const [advertCount, setAdvertCount] = useState(0);

  const getAdverts = async (
    options,
    url = '/Advert?populate=[{"path": "userId"}, {"path": "discipline"}, {"path": "type"}, {"path": "saleType"}]'
  ) => {
    setIsError(false);
    setIsLoading(true);

    try {
      const { data } = await api.get(url, options);
      const {
        data: { count },
      } = await api.get('Advert/count', options);

      setAdvertCount(count);
      setData(data);
    } catch (error) {
      setIsError(true);
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    let query = {
      ...props.query,
    };

    if (!props.includeDeletedAdverts) {
      query = {
        $or: [
          { deletedAt: { $exists: false } },
          { deletedAt: null },
          { status: 1 },
        ],
        ...props.query,
      };
    }

    const options = {
      force: true,
      params: {
        query: query,
        sort: props.sort || { createdAt: -1 },
        limit: props.limit || 100,
      },
    };

    getAdverts(options);
  }, [props.limit, props.query, props.sort, props.includeDeletedAdverts]);

  const removeAdvert = async (id) => {
    try {
      const { data } = await api.patch(`/Advert/${id}`, {
        deletedAt: new Date(),
        syncedAt: new Date(),
      });
      message.success('Removed Advert');
      const options = {
        force: true,
        params: {
          query: { deletedAt: null, ...props.query },
          sort: { createdAt: -1 },
          limit: 100,
        },
      };
      getAdverts(options);
      count(options);
      return data;
    } catch (e) {
      message.error('Could not remove Advert');
    }
  };

  const restoreAdvert = async (id) => {
    try {
      const { data } = await api.patch(`/Advert/${id}`, {
        deletedAt: null,
        syncedAt: new Date(),
      });
      const options = {
        force: true,

        params: {
          query: {
            deletedAt: { $exists: true, $lte: new Date() },
            ...props.query,
          },
          sort: { createdAt: -1 },
          limit: 100,
        },
      };
      getAdverts(options);
      message.success('Restored Advert');
      return data;
    } catch (e) {
      message.error('Could not restore advert');
    }
  };

  const count = async (options = {}) => {
    const { data } = await api.get('Advert/count', options);
    setAdvertCount(data);
    return data;
  };

  const onSearch = async (query) => {
    const defaultOptions = {
      params: {
        query: { deletedAt: { $exists: false }, ...props.query },
        sort: { createdAt: -1 },
        limit: 100,
      },
    };

    const searchOptions = {
      params: {
        query: query,
        limit: 100,
      },
    };

    const options = query ? searchOptions : defaultOptions;

    await getAdverts(options);
  };

  return props.children({
    isLoading,
    isError,
    data,
    removeAdvert,
    restoreAdvert,
    getAdverts,
    onSearch,
    count,
    advertCount,
  });
};

export default AdvertsData;
