import React from 'react';

import { Link } from 'react-router-dom';
import { Table } from 'antd';
import Card from 'Components/Card';
import Media from 'react-media';
import Title from 'Components/Title';
import UsersData from 'Data/UsersData';
import moment from 'moment';

const DashboardUsers = props => {
  const columns = [
    {
      title: 'Name',
      dataIndex: 'profile',
      key: 'profile',
      width: 200,
      render: (profile, r) => {
        const { firstName, lastName } = profile || '';
        return (
          <Link to={`user/${r._id}`}>
            <b>
              {firstName} {lastName}
            </b>
          </Link>
        );
      }
    },
    {
      title: 'E-Mail',
      dataIndex: 'email',
      key: 'email',
      width: 80,
      hideOnSmall: true,
      align: 'left'
    },
    {
      title: 'Joined',
      dataIndex: 'createdAt',
      key: 'createdAt',
      hideOnSmall: true,
      width: 200,
      render: date => {
        return moment(date).calendar(null, {
          sameDay: '[Today]',
          nextDay: '[Tomorrow]',
          nextWeek: 'dddd',
          lastDay: '[Yesterday]',
          lastWeek: 'dddd',
          sameElse: 'DD/MM/YYYY'
        });
      }
    }
  ];
  const getResponsiveColumns = smallScreen =>
    columns.filter(({ hideOnSmall = false }) => !(smallScreen && hideOnSmall));
  return (
    <UsersData query={{ deletedAt: null }} limit={10} sort={{ createdAt: -1 }}>
      {({ isLoading, isError, data: users }) => {
        if (isError) {
          return (
            <div>
              <Title title='There has been an error.' />
            </div>
          );
        }

        return (
          <Card>
            <Title title='Latest 10 Users' />

            <Media query='(max-width: 780px)'>
              {smallScreen => {
                return (
                  <Table
                    showHeader={false}
                    columns={getResponsiveColumns(smallScreen)}
                    dataSource={users}
                    loading={isLoading}
                    rowKey={'_id'}
                    pagination={false}
                  />
                );
              }}
            </Media>
          </Card>
        );
      }}
    </UsersData>
  );
};

export default DashboardUsers;
