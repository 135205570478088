import React, { useState } from 'react';

import { Button, Icon, Layout, Menu } from 'antd';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import auth0Client from 'auth';

const { Content, Sider } = Layout;
const MainLayout = (props) => {
  const [current, setCurrent] = useState('dashboard');

  const handleClick = (e) => {
    setCurrent(e.key);
  };

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sider
        trigger={<Icon type='user' />}
        breakpoint='lg'
        collapsedWidth='0'
        style={{ background: 'white' }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Link to='/dashboard'>
            <h3
              style={{
                fontWeight: 800,
                paddingTop: '2rem',
                paddingBottom: '2rem',
                textAlign: 'center',
                color: '#377F63',
              }}
            >
              Whickr Admin
            </h3>
          </Link>
          {auth0Client.isAuthenticated() && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Menu
                mode='inline'
                theme='light'
                onClick={(e) => handleClick(e)}
                selectedKeys={[current]}
                defaultSelectedKeys={['dashboard']}
              >
                <Menu.Item key='dashboard'>
                  <Link to='/dashboard' style={{ fontWeight: 700 }}>
                    <Icon type='dashboard' />
                    Dashboard
                  </Link>
                </Menu.Item>
                <Menu.Item
                  key='adverts'
                  style={{
                    borderBottomColor: '#377F63',
                    fontWeight: 800,
                  }}
                >
                  <Link to='/adverts' style={{}}>
                    <Icon type='table' />
                    Adverts
                  </Link>
                </Menu.Item>

                <Menu.Item
                  key='users'
                  style={{
                    borderBottomColor: '#377F63',
                    fontWeight: 800,
                  }}
                >
                  <Link to='/users' style={{}}>
                    <Icon type='user' />
                    Users
                  </Link>
                </Menu.Item>

                <Menu.Item
                  key='settings'
                  style={{
                    borderBottomColor: '#377F63',
                    fontWeight: 800,
                  }}
                >
                  <Link to='/settings' style={{}}>
                    <Icon type='setting' />
                    Settings
                  </Link>
                </Menu.Item>

                <Menu.Item
                  key='landing-pages'
                  style={{
                    borderBottomColor: '#377F63',
                    fontWeight: 800,
                  }}
                >
                  <Link to='/landing-pages' style={{}}>
                    <Icon type='table' />
                    Landing Pages
                  </Link>
                </Menu.Item>
              </Menu>
              <div style={{ padding: '1rem', textAlign: 'center' }}>
                <Button
                  style={{ marginTop: '2rem', fontWeight: 700 }}
                  onClick={auth0Client.signOut}
                  block
                  size='large'
                  type='primary'
                  icon='logout'
                >
                  Logout
                </Button>
              </div>
            </div>
          )}
        </div>
      </Sider>
      <Layout>
        <Content
          style={{
            margin: '2rem',
            minHeight: '100vh',
          }}
        >
          {props.children}
        </Content>
      </Layout>
    </Layout>
  );
};

export default withRouter(MainLayout);
