import { Button } from "antd";
import { DashboardModal } from "@uppy/react";
import AwsS3 from "@uppy/aws-s3";
import React, { useState } from "react";
import Uppy from "@uppy/core";
import api from "api";
const uuid = require('uuid');

const ImageUploader = props => {
  const [showModal, setShowModal] = useState(false);

  const getSignedUrl = async file => {

    file.name = uuid.v1()+'.'+file.name.split('.').pop();
    const params = {
      objectName: file.name,
      contentType: file.type
    };

    const { data } = await api.get("/s3/sign", { params });

    const returned = {
      method: "PUT",
      url: data.signedUrl,
      fields: []
    };
    return returned;
  };

  const uppy = Uppy({
    restrictions: {
      maxFileSize: 10000000,
      maxNumberOfFiles: 3,
      minNumberOfFiles: 1,
      allowedFileTypes: ["image/*", "video/*"]
    },
    autoProceed: false
  });

  uppy.use(AwsS3, {
    getUploadParameters(file) {
      return getSignedUrl(file);
    },
    endpoint: "/upload"
  });

  uppy.on("complete", result => props.onSuccess(result.successful));

  return (
    <div>
      <Button size="large" onClick={() => setShowModal(true)}>
        Upload Image
      </Button>
      <DashboardModal
        closeModalOnClickOutside
        open={showModal}
        onRequestClose={() => setShowModal(false)}
        uppy={uppy}
        locale={{
          strings: {
            // Text to show on the droppable area.
            // `%{browse}` is replaced with a link that opens the system file selection dialog.
            dropHereOr: "Drop here or %{browse}",
            // Used as the label for the link that opens the system file selection dialog.
            browse: "browse"
          }
        }}
      />
    </div>
  );
};

export default ImageUploader;
