import React, { useEffect, useState } from 'react';

import { Button, Form, Input, message } from 'antd';
import EditableTable from 'tables/EditableTableComponent';
import Title from 'Components/Title';
import api from 'api';

const ColourManager = (props) => {
  const [colours, setColours] = useState([]);
  const [shouldShowInput, setShowInput] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [newColour, setNewColour] = useState('');

  const columns = [
    {
      title: 'Label',
      dataIndex: 'label',
      key: 'label',
      editable: true,
      render: (item) => {
        return <b>{item}</b>;
      },
    },
    {
      title: 'Used',
      dataIndex: 'adverts_count',
      key: 'adverts_count',
      align: 'center',
      width: 100,
    },
    // {
    //   title: "",
    //   dataIndex: "_id",
    //   key: "action",

    //   render: id => {
    //     return (
    //       <Button
    //         type="danger"
    //         shape="circle"
    //         ghost
    //         size="small"
    //         icon="delete"
    //         onClick={() => removeColour(id)}
    //       />
    //     );
    //   }
    // }
  ];

  const getColours = async (options) => {
    try {
      setIsLoading(true);

      const { data = [] } = await api.get(`/Colour?sort=label`, {
        params: {
          populate: 'adverts_count',
        },
        ...options,
      });

      const onlyWithLabels = data.filter(
        (colour) => colour.label && !colour.deletedAt
      );

      setColours(onlyWithLabels);
      setIsLoading(false);
      return onlyWithLabels;
    } catch (e) {
      setHasError(true);
      console.error(e);
    }
  };

  const addColour = async (colour) => {
    try {
      if (!newColour) {
        return message.error('No new colour entered');
      }

      await api.post(`/Colour`, {
        label: newColour,
      });

      message.success(`Added ${colour} to the list`);
      setNewColour('');

      getColours({ force: true });
    } catch (e) {
      setHasError(true);
      console.error(e);
    }
  };

  const removeColour = async (id) => {
    if (!id) {
      return message.error('You need to provided an ID to remove a Colour');
    }

    try {
      await api.patch(`/Colour/${id}`, {
        deletedAt: new Date(),
      });

      message.success(`Removed successfully`);

      getColours({ force: true });
    } catch (e) {
      setHasError(true);
      console.error(e);
    }
  };

  const updateColour = async (values) => {
    const { _id } = values;
    try {
      await api.patch(`/Colour/${_id}`, {
        label: values.label,
      });
      getColours({ force: true });

      message.success('Updated Colour');
    } catch (e) {
      message.error('Could not update colour');
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await getColours();
    };

    fetchData();
  }, []);

  if (hasError) {
    return (
      <h2>
        Sorry something went wrong. If you know how, please check the console to
        see what the problem is
      </h2>
    );
  }

  return (
    <div>
      <Title
        title='Colours'
        icon={shouldShowInput ? 'close' : 'plus'}
        onClick={() => setShowInput(!shouldShowInput)}
      />
      {shouldShowInput && (
        <div>
          <Input
            style={{ marginTop: '2rem' }}
            allowClear
            onChange={(e, v) => setNewColour(e.target.value)}
            onPressEnter={addColour}
            value={newColour}
            placeholder='Dark Bay'
          />
          <div
            style={{
              marginTop: '2rem',
              marginBottom: '2rem',
              textAlign: 'right',
            }}
          >
            <Button disabled={!newColour} onClick={() => addColour(newColour)}>
              {newColour ? `Add  ${newColour}` : 'Add new colour'}
            </Button>
          </div>
        </div>
      )}

      <EditableTable
        loading={isLoading}
        dataSource={colours}
        columns={columns}
        onSave={updateColour}
        onDelete={removeColour}
      />
    </div>
  );
};

export default Form.create()(ColourManager);
