import { Button, Form, Input, Radio, Spin } from 'antd';
import HorseTypeData from 'Data/HorseTypeData';
import React, { useEffect } from 'react';

function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some((field) => fieldsError[field]);
}

const AddDisciplineForm = (props) => {
  const {
    getFieldDecorator,
    getFieldsError,
    getFieldError,
    isFieldTouched,
    validateFields,
  } = props.form;

  const handleSubmit = (e) => {
    e.preventDefault();
    validateFields((err, values) => {
      if (!err) {
        props.addDiciplineFunction(values);
      }
    });
  };

  // Only show error after a field is touched.
  const usernameError = isFieldTouched('username') && getFieldError('username');
  const passwordError = isFieldTouched('password') && getFieldError('password');

  useEffect(() => {
    return () => validateFields();
  }, [validateFields]);

  return (
    <Form layout='inline' onSubmit={handleSubmit}>
      <Form.Item
        validateStatus={usernameError ? 'error' : ''}
        help={usernameError || ''}
      >
        {getFieldDecorator('label', {
          rules: [{ required: true, message: 'Please input your username!' }],
        })(<Input placeholder='Dressage' />)}
      </Form.Item>
      <Form.Item
        validateStatus={passwordError ? 'error' : ''}
        help={passwordError || ''}
      >
        {getFieldDecorator('type', {
          rules: [{ required: true, message: 'Please input your Password!' }],
        })(
          <HorseTypeData>
            {({ isLoading, isError, data }) => {
              if (isLoading) {
                return <Spin />;
              }
              if (data.length < 1 || isError) {
                return <h4>No types found</h4>;
              }

              return (
                <Form.Item>
                  {getFieldDecorator('type', {
                    initialValue: data[0]._id,
                    rules: [
                      {
                        required: true,
                        message: 'Please provide a type',
                      },
                    ],
                  })(
                    <Radio.Group buttonStyle='solid'>
                      {data &&
                        data.length > 0 &&
                        data.map((v) => {
                          return (
                            <Radio.Button value={v._id} key={v._id}>
                              {v.label}
                            </Radio.Button>
                          );
                        })}
                    </Radio.Group>
                  )}
                </Form.Item>
              );
            }}
          </HorseTypeData>
        )}
      </Form.Item>
      <Form.Item>
        <Button
          type='primary'
          htmlType='submit'
          disabled={hasErrors(getFieldsError())}
        >
          Add Discipline
        </Button>
      </Form.Item>
    </Form>
  );
};

const WrappedAddDisciplineForm = Form.create({ name: 'AddDisciplineForm' })(
  AddDisciplineForm
);
export default WrappedAddDisciplineForm;
