import { setupCache } from 'axios-cache-adapter';
import axios from 'axios';
const cache = setupCache({
  maxAge: 15 * 60 * 1000
});

const api = axios.create({
  baseURL: `/admin/v1`,
  timeout: 30000,
  adapter: cache.adapter
});

export default api;
