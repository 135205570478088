import React from 'react';

import AdvertsData from 'Data/AdvertsData';
import AdvertsTable from '../../tables/AdvertsTable';
import Card from 'Components/Card';
import DisciplineData from 'Data/DisciplineData';
import HorseTypeData from 'Data/HorseTypeData';
import SaleTypeData from 'Data/SaleTypeData';

const AdvertsScene = () => {
  return (
    <React.Fragment>
      <AdvertsData query={{ soldAt: null }}>
        {({
          isLoading,
          isError,
          data,
          restoreAdvert,
          removeAdvert,
          getAdverts,
          onSearch,
          count,
          advertCount
        }) => {
          return (
            <React.Fragment>
              <DisciplineData>
                {({ data: discipline }) => {
                  return (
                    <SaleTypeData>
                      {({ data: saleType }) => {
                        return (
                          <HorseTypeData>
                            {({ data: types }) => {
                              return (
                                <Card>
                                  <AdvertsTable
                                    isLoading={isLoading}
                                    isError={isError}
                                    data={data}
                                    discipline={discipline}
                                    saleType={saleType}
                                    restoreAdvert={restoreAdvert}
                                    removeAdvert={removeAdvert}
                                    getAdverts={getAdverts}
                                    onSearch={onSearch}
                                    count={count}
                                    advertCount={advertCount}
                                    types={types}
                                  />
                                </Card>
                              );
                            }}
                          </HorseTypeData>
                        );
                      }}
                    </SaleTypeData>
                  );
                }}
              </DisciplineData>
            </React.Fragment>
          );
        }}
      </AdvertsData>
    </React.Fragment>
  );
};

export default AdvertsScene;
