import { useEffect, useState } from 'react';

import { message } from 'antd';

import getAll from '../../getAll';
import removeItem from '../../removeItem';

const LandingPagesData = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [data, setData] = useState([]);

  async function getLandingPages() {
    setIsError(false);
    setIsLoading(true);

    try {
      // aws fetch here
      const data = await getAll(
        'LandingPage-y5qxqcfgvfhuligxdw6cz2oizy-production'
      );
      console.log('datatatatra', data);

      setData(data);
    } catch (error) {
      setIsError(true);
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getLandingPages();
  }, []);

  const removeLandingPage = async (id) => {
    try {
      await removeItem('LandingPage-y5qxqcfgvfhuligxdw6cz2oizy-production', id);

      getLandingPages();

      return data;
    } catch (e) {
      message.error('Could not remove Advert');
    }
  };

  return props.children({
    isLoading,
    isError,
    data,
    removeLandingPage,
    getLandingPages,
  });
};

export default LandingPagesData;
