import React from "react";

import { Row } from "antd";
import Card from "Components/Card";
import UserManager from "Components/UserManager";
// import MetricsManager from "Components/MetricsManager";

const UsersScene = () => {
  return (
    <div>
      <Row gutter={8}>{/* <MetricsManager /> */}</Row>
      <Card>
        <UserManager />
      </Card>
    </div>
  );
};

export default UsersScene;
