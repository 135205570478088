import React, { useState } from 'react';

import {
  Button,
  Col,
  Icon,
  Input,
  Modal,
  Popconfirm,
  Result,
  Row,
  Table,
  Tabs,
  Tag,
} from 'antd';
import { Link } from 'react-router-dom';
import Media from 'react-media';
import Title from 'Components/Title';
import _ from 'underscore';
import moment from 'moment';

import './AdvertsTable.css';
import AdvertForm from 'scenes/AdvertScene/AdvertForm';

const { TabPane } = Tabs;

const tableTabsType = [
  { label: 'Adverts', _id: '1' },
  { label: 'Deleted Adverts', _id: '2' },
  { label: 'Sold Adverts', _id: '3' },
];
const AdvertsTable = ({
  data,
  isLoading,
  isError,
  history,
  discipline,
  saleType,
  removeAdvert,
  restoreAdvert,
  getAdverts,
  count,
  types,
  onSearch,
  advertCount,
}) => {
  const [statePagination] = useState();
  const [showModal, setshowModal] = useState(false);
  const [searchKey] = useState('headline');
  const [searchType] = useState();
  const [filteredInfo] = useState({ status: [1] });
  const filteredDiscipline = _.uniq(discipline, 'label');

  if (isError) {
    return (
      <Result
        status='500'
        title='Server Error'
        subTitle='Sorry, there was an error getting advert data'
      />
    );
  }

  const columns = [
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 50,
      filters: [
        { text: 'Draft', value: 2 },
        { text: 'Live', value: 1 },
      ],
      onFilter: (value, record) => record.status === value,
      filteredValue: filteredInfo.status || null,
      align: 'center',
      render: (i) => {
        if (i === 2) {
          return (
            <Media query='(max-width: 1470px)'>
              {(matches) =>
                matches ? (
                  <Icon type='check-circle' theme='twoTone' />
                ) : (
                  <Tag color='blue'>Draft</Tag>
                )
              }
            </Media>
          );
        }

        if (i === 1) {
          return (
            <Media query='(max-width: 1470px)'>
              {(matches) =>
                matches ? (
                  <Icon
                    type='check-circle'
                    theme='twoTone'
                    twoToneColor='#52c41a'
                  />
                ) : (
                  <Tag color='green'>Live</Tag>
                )
              }
            </Media>
          );
        }
      },
    },
    {
      title: 'Headline',
      dataIndex: 'headline',
      key: 'headline',
      width: 100,
      render: (v, { _id }) => {
        return (
          <b>
            <Link to={`/adverts/${_id}`}>{v}</Link>
          </b>
        );
      },
    },

    {
      title: 'Created',
      dataIndex: 'createdAt',
      width: 100,
      defaultSortOrder: 'descend',

      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => {
        const aDate = new Date(a.createdAt);
        const bDate = new Date(b.createdAt);
        return aDate - bDate;
      },
      key: 'createdAt',
      render: (date) => {
        return moment(date).format('DD-MM-YY');
      },
    },

    {
      title: 'Discipline',
      dataIndex: 'discipline',
      key: 'discipline',

      width: 130,
      filters: filteredDiscipline.map((discipline) => ({
        text: discipline.label,
        value: discipline._id,
      })),
      onFilter: (value, record) => {
        const ids = record.discipline.map((d) => d._id);
        return ids.indexOf(value) === 0;
      },

      render: (discipline) => {
        return (
          <span>
            {discipline &&
              discipline.map((discipline) => {
                if (discipline && discipline.label) {
                  return (
                    <Tag
                      color={'volcano'}
                      key={discipline._id}
                      style={{ margin: 2 }}
                    >
                      {discipline.label.toUpperCase()}
                    </Tag>
                  );
                }
                return null;
              })}
          </span>
        );
      },
    },
    {
      title: 'Type',
      dataIndex: 'type.label',
      key: 'type',
      width: 80,
      hideOnSmall: true,
      filters:
        types && types.length > 0
          ? types.map((type) => ({ text: type.label, value: type._id }))
          : [],
      onFilter: (value, record) => record.type._id === value,
    },
    {
      title: 'Sale Type',
      dataIndex: 'saleType',
      key: 'saleType',
      width: 80,
      hideOnSmall: true,
      render: (sType) => {
        if (sType) {
          return (
            <span>
              <Tag
                color={sType.label === 'Sale' ? 'blue' : 'purple'}
                key={sType._id}
                style={{ margin: 2 }}
              >
                {sType && sType.label && sType.label.toUpperCase()}
              </Tag>
            </span>
          );
        }
      },
      onFilter: (value, record) => record.saleType._id === value,
      filters:
        saleType && saleType.length > 0
          ? saleType.map((type) => ({ text: type.label, value: type._id }))
          : [],
    },
    {
      title: 'Breed',
      dataIndex: 'breed',
      key: 'breed',
      width: 100,

      hideOnSmall: true,
    },
    {
      title: 'Location',
      dataIndex: 'location',
      key: 'location',
      hideOnSmall: true,
      width: 100,
    },
    {
      title: 'User',
      dataIndex: 'userId',
      key: 'userId',
      width: 100,
      render: (user, r) => {
        if (!user) {
        }

        const { _id, profile } = user || {};

        const { firstName, lastName } = profile || '';
        return (
          <b>
            <Link to={`/user/${_id}`}>
              {firstName} {lastName}
            </Link>
          </b>
        );
      },
    },

    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price',
      width: 100,
      hideOnSmall: true,
      defaultSortOrder: 'ascend',
      sorter: (a, b) => {
        const fPrice = a.price ? parseInt(a.price) : 0;
        const sPrice = b.price ? parseInt(b.price) : 0;

        return fPrice - sPrice;
      },
      render: (i) => {
        if (i) {
          const number = parseInt(i, 10);
          return number.toLocaleString('en', {
            style: 'currency',
            currency: 'GBP',
          });
        }
        return 'POA';
      },
    },
    {
      title: '',
      dataIndex: 'edit',
      key: 'edit',
      width: 100,

      render: (i, r) => (
        <div>
          <Link to={`adverts/${r._id}`}>
            <Button
              type='primary'
              shape='circle'
              icon='edit'
              ghost
              size='small'
              style={{ margin: '10px' }}
            />
          </Link>
          {r.deletedAt ? (
            <Button
              onClick={() => restoreAdvert(r._id)}
              icon='undo'
              type='ghost'
              shape='circle'
              size='small'
            />
          ) : (
            <Popconfirm
              placement='topLeft'
              title='Remove Advert?'
              onConfirm={() => removeAdvert(r._id)}
              okText='Yes'
              cancelText='No'
            >
              <Button
                icon='delete'
                type='danger'
                shape='circle'
                ghost
                size='small'
              />
            </Popconfirm>
          )}
        </div>
      ),
    },
  ];

  const onChangeTab = (key) => {
    let options = {};
    // [{ deletedAt: { $exists: true } }, { deletedAt: { $ne: null } }];

    if (key === '1') {
      options = {
        force: true,
        params: {
          query: {
            $or: [{ deletedAt: { $exists: false } }, { deletedAt: null }],
            soldAt: null,
          },
        },
      };
    }

    if (key === '2') {
      options = {
        force: true,
        params: {
          query: {
            deletedAt: { $exists: true, $lte: new Date() },
          },
        },
      };
    }

    if (key === '3') {
      options = {
        force: true,
        params: {
          query: {
            $and: [{ soldAt: { $exists: true } }, { soldAt: { $ne: null } }],
          },
        },
      };
    }

    getAdverts(options);
  };

  const getResponsiveColumns = (smallScreen) =>
    columns.filter(({ hideOnSmall = false }) => !(smallScreen && hideOnSmall));

  const handleSearch = (term) => {
    let query = {};

    if (searchType === '$equals') {
      query = {
        [searchKey]: term,
        deletedAt: { $exists: 0 },
      };
    } else {
      // const searchTerm = new RegExp(term);

      query = {
        [searchKey]: { $regex: term, $options: 'i' },
        deletedAt: { $exists: 0 },
      };
    }

    onSearch(query);
  };

  return (
    <React.Fragment>
      <Modal
        width={820}
        title='Create Advert'
        footer={null}
        visible={showModal}
        onOk={() => setshowModal(false)}
        onCancel={() => setshowModal(false)}
      >
        <AdvertForm />
      </Modal>

      <Row gutter={8}>
        <Row>
          <Col xs={24} sm={24} md={24} lg={12} xl={14}>
            <Title title='Adverts' count={advertCount} />
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={10}>
            {/* <InputGroup style={{ marginBottom: '1.2rem' }}>
              <Select
                defaultValue={searchKey}
                onChange={value => setSearchKey(value)}
              >
                <Option value='headline'>Headline</Option>
                <Option value='_id'>ID</Option>
                <Option value='breed'>Breed</Option>
              </Select>
              <Select
                defaultValue={searchType}
                onChange={value => setSearchType(value)}
              >
                <Option value='$contains'>Contains</Option>
                <Option value='$equals'>Equals</Option>
              </Select>
            </InputGroup> */}
            <Input.Search
              style={{ marginBottom: '2rem' }}
              placeholder='Superb & Genuine Competition Pony'
              size='large'
              allowClear
              onChange={(e) => {
                if (!e.target.value) {
                  onSearch(null);
                }
              }}
              onSearch={handleSearch}
            />
          </Col>
        </Row>
        <Row>
          <Col offset={16} span={8} align='right'>
            <Button
              size='large'
              onClick={() => setshowModal(true)}
              icon='plus'
              type='primary'
            >
              Create Advert
            </Button>
          </Col>
        </Row>
      </Row>

      <Media query='(max-width: 1470px)'>
        {(smallScreen) => {
          return (
            <Tabs style={{ marginTop: '2rem' }} onChange={onChangeTab}>
              {tableTabsType.map((type) => {
                let useColumns = getResponsiveColumns(smallScreen);

                if (type._id === '3') {
                  const soldCol = {
                    title: 'Sold',
                    dataIndex: 'soldAt',
                    width: 100,
                    defaultSortOrder: 'descend',

                    sortDirections: ['descend', 'ascend'],
                    sorter: (a, b) => {
                      const aDate = new Date(a.soldAt);
                      const bDate = new Date(b.soldAt);
                      return aDate - bDate;
                    },
                    key: 'soldAt',
                    render: (date) => {
                      if (date === undefined) {
                        return 'N/A';
                      } else {
                        return moment(date).format('DD-MM-YY');
                      }
                    },
                  };

                  useColumns.splice(5, 0, soldCol);
                }

                return (
                  <TabPane
                    tab={type.label}
                    key={type._id}
                    disabled={type.disabled}
                  >
                    <Table
                      rowClassName={(r, i) =>
                        r.deletedAt ? 'removedAdvert' : r.soldAt && 'soldAdvert'
                      }
                      loading={isLoading}
                      rowKey='_id'
                      dataSource={data}
                      columns={useColumns}
                      pagination={statePagination}
                      // onChange={(pagination, filters, sorter) =>
                      //   handleTableChange(pagination, filters, sorter, type)
                      // }
                    />
                  </TabPane>
                );
              })}
            </Tabs>
          );
        }}
      </Media>
    </React.Fragment>
  );
};

export default AdvertsTable;
